/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export const Star = (props) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="presentation"
      tabIndex="-1"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <rect width="24" height="24" fill="none" />
      <polygon
        className="checked"
        points="12,15.8 16.3,18.4 15.1,13.6 18.9,10.4 13.9,9.9 12,5.4 10.1,9.9 5.2,10.4 8.9,13.6 7.8,18.4 "
      />
      <path
        className="border"
        d="M22,9.2l-7.2-0.6L12,2L9.2,8.6L2,9.2L7.5,14l-1.6,7l6.2-3.7l6.2,3.7l-1.6-7L22,9.2z M12,15.4l-3.8,2.3l1-4.3l-3.3-2.9
	l4.4-0.4l1.7-4l1.7,4l4.4,0.4l-3.3,2.9l1,4.3L12,15.4z"
      />
    </svg>
  );
};
