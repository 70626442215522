/* eslint-disable import/prefer-default-export */
export const scrapedReviews = [
  {
    autor_link: 'https://www.google.com/maps/contrib/113882128491911828995?hl=en-US',
    autor_name: 'Krishnadas',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJzZo8y0Oq397sCEYnXSApbond2lbw_OvOW4JztV=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text:
      'Great place by the river for Indian food and beer. Have been a regular here for their value of money lunch sets and also tasty dinners. Their service & food never disappoints!',
    review_img_url: 'https://lh5.googleusercontent.com/p/AF1QipMmw0PEXwRurZCkEzcvUtOYTujqMeG0mKTPAHsJ',
    owner_answer:
      'Thank you 🙏for being our patron and your kind review.\r\n\r\nWe look forward to being at your service soon with more great Indian "Zaika\'s" 🙏. See you soon.',
    owner_answer_timestamp: '1619014106',
    owner_answer_timestamp_datetime_utc: '04/21/2021 14:08:26',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s113882128491911828995!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618979185',
    review_datetime_utc: '04/21/2021 04:26:25',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/109750453387027509250?hl=en-US',
    autor_name: 'Monica 95',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GiHxspZMqMRgsGTajRtMtLyk37pD3cW0WZg6aNqGw=s120-c-c0x00000000-cc-rp-mo-ba4-br100',
    review_text:
      'This place is undoubtedly one of the best Indian restaurant in SG, with great food and the staff are very friendly too. 🙂🙂Missed  to click a picture of panner gravy and the  Mango Lassi.\r\nOh and Loved the complimentary dessert at the end of our dinner🙃',
    review_img_url: 'https://lh5.googleusercontent.com/p/AF1QipPbWrh1CVpLa7muSwLJU6T4HlxdGaexAZOr6qDR',
    owner_answer:
      "Thank you Monica 🙏for your feedback, posting great photos and rating us as one of the Best Indian Restaurant in Singapore !!!\r\n\r\nIt's great to hear you loved the food with rich Indian Zaika's (flavours) and our hospitality (based on one of core values Atithi Devo Bhava [अतिथिदेवो भव:))] .\r\n\r\nWe look forward to seeing you soon with your friends & dear ones.",
    owner_answer_timestamp: '1618976847',
    owner_answer_timestamp_datetime_utc: '04/21/2021 03:47:27',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s109750453387027509250!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618907219',
    review_datetime_utc: '04/20/2021 08:26:59',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/110651958606620480842?hl=en-US',
    autor_name: 'Divyanshu Mahajan',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GhdRD8OZsx9cq0RKAOvlUHdoO6MVkWx0Wthm7jxbrg=s120-c-c0x00000000-cc-rp-mo-ba6-br100',
    review_text:
      'Located in the bustling Boat Quay restaurant row along the river, it stands out in its service and hospitality. The food is delicious, well flavoured and each dish was perfect to our liking. There is a difference in taste with comparison to the other places and this makes it unique among the many Indian places along the block. The tandoori chicken was the best one in recent memory.',
    review_img_url: 'https://lh5.googleusercontent.com/p/AF1QipPEecClyw5dqPc3F75NLhaM-BT4cgaI63aHRkG1',
    owner_answer: 'Thank you very much sir\r\nPlease do come again 🙏🙏',
    owner_answer_timestamp: '1607241081',
    owner_answer_timestamp_datetime_utc: '12/06/2020 07:51:21',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s110651958606620480842!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1607238427',
    review_datetime_utc: '12/06/2020 07:07:07',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/118134420243981951912?hl=en-US',
    autor_name: 'Sandeep Jagadeesh',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJyiE8cQj1TbwrOKtM-K6GtG2KbfPGCKEL1odtw2=s120-c-c0x00000000-cc-rp-mo-ba4-br100',
    review_text:
      'The location is very good, walkable from Clarke quay mrt. The view from the tables outside is awesome. In terms of food, you get some of the best indian dishes. Some of their not to miss are fish curry, garlic chicken tikka, channa chat, Dal tadka, lime soda. One more thing to mention is the outstanding service of the staff here, the owner Raju is a very friendly person and will take very good care of the restaurant customers.',
    review_img_url: 'https://lh5.googleusercontent.com/p/AF1QipO5q2jI8Qzx2zC_QnX8D-xQo0Ib8DU-u_nwkCse',
    owner_answer: 'Mr sandeep jagadeesh\r\nThank you very much sir your visiting our restaurant very kind wishes 🙏🙏',
    owner_answer_timestamp: '1602962851',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:27:31',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s118134420243981951912!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1599292073',
    review_datetime_utc: '09/05/2020 07:47:53',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/115647010475829140332?hl=en-US',
    autor_name: 'Ashley Yap',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJz-TuTI_-RjHMcaJheNcLIq7wI4BmsgFUpb8a-0Cg=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Great food. Great service. A perfect place to spend with your friends or colleagues if you are up for an authentic Tandoori feast. Good selection of drinks are available too. Overall, we had a great time here and definitely a must try.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJz-TuTI_-RjHMcaJheNcLIq7wI4BmsgFUpb8a-0Cg',
    owner_answer:
      "Thank you Ashley 🙏. Its humbling to know you loved our food & service and you had great time with your friends every time you visit.\r\n\r\nThanks for rating us the highest for its Tandoori Zaika's (flavours) and Look forward to see you soon with your friends & colleagues 🙏.",
    owner_answer_timestamp: '1620527451',
    owner_answer_timestamp_datetime_utc: '05/09/2021 02:30:51',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s115647010475829140332!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1620298646',
    review_datetime_utc: '05/06/2021 10:57:26',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/108251850224444940853?hl=en-US',
    autor_name: 'Sandeep Gowda',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14Gix4PYYEbIjF3OlWNmniOAH0GigF0GztTR7EQm3lg=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'This beautiful restaurant has left the best impressions! Good Hospitality, delicious Indian food, beautiful presentation and wonderful desserts to finish with.\r\nI recommend this place to everyone! I would like to come back here again and again.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14Gix4PYYEbIjF3OlWNmniOAH0GigF0GztTR7EQm3lg',
    owner_answer:
      'Thank you Sandeep 🙏for being our patron and recommending us to you esteemed connects.\r\n\r\nIts great to know you love our food, hospitality and have had great time at our place. We will be waiting for you to be back soon...',
    owner_answer_timestamp: '1619014427',
    owner_answer_timestamp_datetime_utc: '04/21/2021 14:13:47',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s108251850224444940853!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618980819',
    review_datetime_utc: '04/21/2021 04:53:39',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/116882546940712339399?hl=en-US',
    autor_name: 'leo rajesh',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJzFRDslrvoOsDFVjEwl3GwHL96Lbmb_IgzgAQEN=s120-c-c0x00000000-cc-rp-mo-ba2-br100',
    review_text:
      'Delicious food, best service with a beautiful view!\r\n\r\nRecommend everyone to visit and you will always go back!\r\n\r\nThanks for providing such a good service!',
    review_img_url: 'https://lh5.googleusercontent.com/p/AF1QipOI0bicA92IkGYa__qQ2b19zNgEjGhtLKyixBWq',
    owner_answer: 'Thank you very much  Mr Leo Rajesh your kind saport 🙏🙏',
    owner_answer_timestamp: '1602961430',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:03:50',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s116882546940712339399!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1602007024',
    review_datetime_utc: '10/06/2020 17:57:04',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/104109857974431516951?hl=en-US',
    autor_name: 'Sufian Jumahri',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJzFgup2VmKDUWD1EdU1hpKplb9K_CGsGLV9O-U9=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'This was the first time my friend and I ever dined at TandooriZaika and we must say it went truly beyond my expectations. Service is impeccable and staff is very attentive. Chef Raju even went out his way to check on us and treated us like close friends. He will ensure all orders are made to your specifications. The food is halal but do take note that the restaurant serve alcohol.\r\n\r\nWe ordered 6 dishes, namely, Manchow Soup, Vegetarian Samosa, Chicken Tikka Masala, Prawn Tikka, Fish Briyani and Chicken Curry. If you love spicy food, you simply cannot go wrong with any of these dishes. Absolutely mouth watering and they are all healthy as Chef Raju uses less oil and avoid MSG. He also offers vegetarian and vegan fare.\r\n\r\nI would highly recommend Tandoori Zaika to those looking for authentic North Indian food and who expect extraordinary service. You will not be disappointed.\r\n\r\nWe’ll certainly be back!',
    review_img_url: 'https://lh5.googleusercontent.com/p/AF1QipM0qO2qn2RtsoTs4CDi4SSD7tdc3T38ECIc8_Hm',
    owner_answer:
      'Thank you very much sir your visiting our restaurant 🙏 please do come again stay fit stay healthy 💪',
    owner_answer_timestamp: '1603618379',
    owner_answer_timestamp_datetime_utc: '10/25/2020 09:32:59',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s104109857974431516951!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1603117638',
    review_datetime_utc: '10/19/2020 14:27:18',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/114018588382193811837?hl=en-US',
    autor_name: 'Vaibhav Vishal',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJxhvGt14uBgVTR6qPhV5-td0W9IrfDYGqKqnQNu=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Amazing food in the town. Must try they have good variety. Very friendly hosts they make you feel homely. Service is very good.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJxhvGt14uBgVTR6qPhV5-td0W9IrfDYGqKqnQNu',
    owner_answer:
      "Thanks Vishal 🙏🙏🙏and appreciate your feedback our our food and service. We strive and will continue for excellence.\r\n\r\nLook forward to see you soon with your friends, colleagues and dear ones for enjoying more Zaika's (flavours).",
    owner_answer_timestamp: '1620008163',
    owner_answer_timestamp_datetime_utc: '05/03/2021 02:16:03',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s114018588382193811837!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1619796010',
    review_datetime_utc: '04/30/2021 15:20:10',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/115504067087230304375?hl=en-US',
    autor_name: 'Carsten Kreuzer',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14Gh7QCu87lrCor1W6jE6M7Gc9HGkYyWx0wrjv7n9Noc=s120-c-c0x00000000-cc-rp-mo-ba5-br100',
    review_text:
      'Nice service, adjusted swiftly to time pressure we had, while always staying friendly. Nice atmosphere and good food being less spicy versus similar restaurants close by. Recommend to go there, particularly not eating very spicy though in order to explicitly mention that.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14Gh7QCu87lrCor1W6jE6M7Gc9HGkYyWx0wrjv7n9Noc',
    owner_answer: 'Thank you very much sir your visiting our restaurant please do come again 🙏🙏',
    owner_answer_timestamp: '1616664603',
    owner_answer_timestamp_datetime_utc: '03/25/2021 09:30:03',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s115504067087230304375!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1616467026',
    review_datetime_utc: '03/23/2021 02:37:06',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/106267813958093725949?hl=en-US',
    autor_name: 'Smita Bhattacharya',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GjKLnANNhToiWjLd8Yl3fdgluDtnpBO6C5tYV4YSQ=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'We had amazing food delivered for our celebration, everyone loved the quality, taste and quantity of dishes. Highly recommended, you wont be disappointed :)',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GjKLnANNhToiWjLd8Yl3fdgluDtnpBO6C5tYV4YSQ',
    owner_answer:
      "Thank you Smita 🙏,\r\n\r\nIts great to hear we could make your celebration special for you, your family and friends with great quality food and Indian Zaika's (flavours) which our team strive for.\r\n\r\nLook forward to being at your service for more such celebration. Hope to see you soon once we have our restaurant open for dine-in (post Covid-19 restrictions are eased), in meantime enjoy our delicacy at the comfort of your home. Stay Safe !",
    owner_answer_timestamp: '1622273568',
    owner_answer_timestamp_datetime_utc: '05/29/2021 07:32:48',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s106267813958093725949!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1622193700',
    review_datetime_utc: '05/28/2021 09:21:40',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/113719386694804497709?hl=en-US',
    autor_name: 'Mike',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14Gia03nXleH_D-c30gsLuF5z2DtOEg07Dp0-P2Lt=s120-c-c0x00000000-cc-rp-mo-ba6-br100',
    review_text:
      "I would provide zero star if possible , but unfortunately I cannot. I can not judge the food, because after one hour of sitting here, we still haven't got any food. All the other tables, in which they came after us, have all been eating for a while. They typical food is coming and have not come yet.\r\n\r\nIt seems like they cater to local clientele , probably not serving our table for a reason.\r\n\r\nOverall I would rate this place with the following:\r\n\r\nLocation: 10/10\r\nAmbient: 5/10\r\nTaste: 0/10\r\nService: 0/10\r\nPrice: 5/10",
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14Gia03nXleH_D-c30gsLuF5z2DtOEg07Dp0-P2Lt',
    owner_answer:
      'Mr Mike,\r\nI am the manager of this restaurant and me and my staff are truly sorry for the inconvenience caused for you and your friends.I hope you take my sincere apology.\r\nThank you,\r\nFrom:Raju (manager)\r\n🙏🙏\r\nfor what we did.\r\nWe are sorry about that we have your feedback doing better',
    owner_answer_timestamp: '1616333011',
    owner_answer_timestamp_datetime_utc: '03/21/2021 13:23:31',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s113719386694804497709!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '1',
    review_timestamp: '1616155763',
    review_datetime_utc: '03/19/2021 12:09:23',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/112419264029456976746?hl=en-US',
    autor_name: 'RV Fitness Journey',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GhaMYmpF1GNCMbWfYri6MWKylbQ-e_LcSEdRGTcLw=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'OMG..The food is prefectly awesome. I love the Tandoori Chicken and Mango laasi. The staff are very accomodating and very friendly. They are very patience too to us and very attentive to what we need.     I love this place and will definitely come back. I also love the environment,its breezy and its overlooking the Marina Bay Sands. Highly recommended.\r\n\r\nMore power..',
    review_img_url: 'https://lh5.googleusercontent.com/p/AF1QipORE4f3Bdx8NoAKMmwUDzVOZ3AB-LtYeiAIbMh7',
    owner_answer:
      'Thank you very much your visiting our restaurant 🙏🙏 please do come again stay fit stay healthy 🙏💪🏋\u200d♀.',
    owner_answer_timestamp: '1603618315',
    owner_answer_timestamp_datetime_utc: '10/25/2020 09:31:55',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s112419264029456976746!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1603258344',
    review_datetime_utc: '10/21/2020 05:32:24',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/108945845662059855150?hl=en-US',
    autor_name: 'Kiran Veeramallu',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GhKMIRD2Os749Xhev_QtUcsYClqhRxIeTCpXGEg7g=s120-c-c0x00000000-cc-rp-mo-ba5-br100',
    review_text: 'Little pricey but good food',
    review_img_url: 'https://lh5.googleusercontent.com/p/AF1QipMwXXTxAoAMoEIWYrBah-eDpaHQZWDl3AEHB7a1',
    owner_answer: 'thank you very much your visit our restaurant we have your feedback and doit better.',
    owner_answer_timestamp: '1607407616',
    owner_answer_timestamp_datetime_utc: '12/08/2020 06:06:56',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s108945845662059855150!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1607091974',
    review_datetime_utc: '12/04/2020 14:26:14',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/103204675361474755854?hl=en-US',
    autor_name: 'Santosh Yadav',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GjXNdAEsCBFCOJVKW69eFx0h3ERuz0B6KYRFyDxPws=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text:
      'One of the best Indian restaurants in Singapore. Amazing service, especially by Ms. Kinosha! Price is  really good for a restaurant with nice ambience that is along the Singapore river.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GjXNdAEsCBFCOJVKW69eFx0h3ERuz0B6KYRFyDxPws',
    owner_answer:
      "Thank you Santosh ji 🙏🙏for rating us as one of the best Indian restaurant in Singapore.\r\n\r\nIt always pleasure to know our guest's loved our food, ambience and service. We appreciate you motivating our staff by mentioning their good work. We look forward to seeing you soon and being at your service again with more Indian Zaika’s.",
    owner_answer_timestamp: '1618543900',
    owner_answer_timestamp_datetime_utc: '04/16/2021 03:31:40',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s103204675361474755854!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618465204',
    review_datetime_utc: '04/15/2021 05:40:04',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/101946647288831953102?hl=en-US',
    autor_name: 'Jason Cheong',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GhiCFA0Oh5RU6dZRoIXTqVnT_gLimnLHsmvuX2Qgg=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'As the restaurant is located along side Boat Quay, it has a great ambience to hang out with some friends and families over a nice meal.  The food was absolutely delicious, most particularly the lamb cutlets and fish tikka that we had ordered.  The staff were very attentive and friendly, and overall it was a very good dining experience for us.  Will definitely come back again to try the other Tandoor dishes it has to offer.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GhiCFA0Oh5RU6dZRoIXTqVnT_gLimnLHsmvuX2Qgg',
    owner_answer:
      "Thank you Jason 🙏,\r\n\r\nIt's great to hear you love the food, Zaika's (flavours), service (based on one of core values Atithi Devo Bhava [अतिथिदेवो भव:))] and ambience at our restaurant.\r\n\r\nWe look forward to being at your service with more Tandoori \"Zaika's\" 🙏.",
    owner_answer_timestamp: '1621745856',
    owner_answer_timestamp_datetime_utc: '05/23/2021 04:57:36',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s101946647288831953102!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1621770686',
    review_datetime_utc: '05/23/2021 11:51:26',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/108496788958098125476?hl=en-US',
    autor_name: 'Venky Ayyappan',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJwX7B7PWKAT7T7KL2CsIXVoF84ulIYeDm-osQvC=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Best service and staff. Food is amazing. Loved lamb dishes. My kid loves chicken 65 😋.\r\nMUST try.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJwX7B7PWKAT7T7KL2CsIXVoF84ulIYeDm-osQvC',
    owner_answer:
      "Thank you Venky 🙏and it's great to know you loved our food & hospitality. Its even pleasing to know your kid loved the chicken 65 :) . Look forward to seeing you & your family soon.",
    owner_answer_timestamp: '1619013340',
    owner_answer_timestamp_datetime_utc: '04/21/2021 13:55:40',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s108496788958098125476!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618978146',
    review_datetime_utc: '04/21/2021 04:09:06',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/109169660162234831936?hl=en-US',
    autor_name: 'Joseph Rajkumar Stephen',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GimDFRPko1LJSxl82xBiz1XVSjkxVPQOjyv4rMJ=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'This is a fantastic Indian restaurant!\r\nThe food is superb and the service is excellent. My daughters loved the food and the lavish attention by every member of Tandoori Zaika team. The Zaika team treats you as special guest. My youngest daughter goes into total silence when the food is great and starts enjoying - Ah a sure sign of best food.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GimDFRPko1LJSxl82xBiz1XVSjkxVPQOjyv4rMJ',
    owner_answer:
      "Thank you Mr. Joseph 🙏🙏🙏and its great that you and daughter's loved our food, service and hospitality.\r\n\r\nOne of our core value is Atithi Devo Bhava [अतिथिदेवो भव:))] and its always good for us to know that our guests feel and appreciate this.\r\n\r\nWe look forward to seeing you soon again with your family, friends & colleague and have good time along with some Indian Zaika (flavour).",
    owner_answer_timestamp: '1618658267',
    owner_answer_timestamp_datetime_utc: '04/17/2021 11:17:47',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s109169660162234831936!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618576172',
    review_datetime_utc: '04/16/2021 12:29:32',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/110700617329680568646?hl=en-US',
    autor_name: 'Sudhir Dubey',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GhQ8SCTVJ5PW3zuDbyzyNCuQm9_4VGs0GwfNHlk=s120-c-c0x00000000-cc-rp-mo-ba5-br100',
    review_text: 'Good food and excellent service',
    review_img_url: 'https://lh5.googleusercontent.com/p/AF1QipMgt2euI76jXG1z3jGPzk-5-ZoUlK3pldISfw73',
    owner_answer: 'Thank you very much sir 🙏\r\nYour visit our restaurant please do come back again..',
    owner_answer_timestamp: '1609350979',
    owner_answer_timestamp_datetime_utc: '12/30/2020 17:56:19',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s110700617329680568646!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1609341837',
    review_datetime_utc: '12/30/2020 15:23:57',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/115282727665513068447?hl=en-US',
    autor_name: 'Strazer',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJzPEyoKBWqsbvPebCkliJ5rUrUnVa0pAE2kfnmb=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Walked in for lunch\r\nDespite lots of choices on menu told we could not order dishes of choice and were told what 2 dishes we would get by waiter.\r\nDespite the strong urge to walk out we persisted. Ordered some starters and drink only.\r\nFood - mediocre!\r\nService - poor\r\nCost - high !   Paid 20 to 25% above menu prices for each item.\r\nNot going to return - Ever !',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJzPEyoKBWqsbvPebCkliJ5rUrUnVa0pAE2kfnmb',
    owner_answer:
      'Dear Straz,\r\n\r\nFirstly Sorry for your experience. We value each our guest and put our best effort to treat them with the principal of Atithi Devo Bhava [अतिथिदेवो भव:))]\r\n\r\nI am the manager of this restaurant and I have discussed with my staff. I am informed that you visited us at 02:30pm and wanted to have our Lunch set menu (which has been referred to have lot of choices). Our Lunch set menu is only available until 02:00 pm and our staff has informed that its over, however we did extend the offer with options that was available.\r\n\r\nWe appreciate you deciding to go with Ala-cart option which was recommended and I am sorry to hear that you were not satisfied with our service.\r\n\r\nThe prices for Ala-cart mentioned in our menu excludes the service charges of 10% and its mentioned in our menu. I am surprised to know that you find it 20-25% higher. There seems to be some confusion.\r\n\r\nHowever, if you can bring the receipt for the mentioned visit and we can compare with our menu.\r\nIf the price is more than our prices mentioned in our menu + service charge of 10%,  we will be more than happy to refund you the complete receipt amount.\r\n\r\nWe are again sorry for the experience and hope you visit us again. We will ensure you have a better experience.\r\n\r\nThank you,\r\n\r\nFrom:\r\nRaju\r\n(Manager)\r\n🙏🙏',
    owner_answer_timestamp: '1617766413',
    owner_answer_timestamp_datetime_utc: '04/07/2021 03:33:33',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s115282727665513068447!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '1',
    review_timestamp: '1617693652',
    review_datetime_utc: '04/06/2021 07:20:52',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/110004621996121113361?hl=en-US',
    autor_name: 'anshika gupta',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GiF_Zm4Nf5UCa8-_DfjU2vb5dLrYaZDP9hGANFyBw=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text:
      'Price is definitely worth it for the view, customer service and most importantly the food!! I had dinner with my family here and I was not disappointed. The staff really do make sure that you are having a good time and that all of your needs are being taken care of. The food was delicious. It is a good place to bond with colleagues or family over Indian food. Do try it out!!!',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GiF_Zm4Nf5UCa8-_DfjU2vb5dLrYaZDP9hGANFyBw',
    owner_answer: 'Thank you very much 🙏🙏\r\nPlease do come back.',
    owner_answer_timestamp: '1608014656',
    owner_answer_timestamp_datetime_utc: '12/15/2020 06:44:16',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s110004621996121113361!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1608013889',
    review_datetime_utc: '12/15/2020 06:31:29',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/104171482675343226151?hl=en-US',
    autor_name: 'Kumaraprasad Ganesan',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GhXlAYZoHZC3KEZ5Sp2WzEhSRd1ceYjaXot-t94Gg=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Loved the hospitality of Kinosha. Great place to hang out with friends. Best place for Indian food',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GhXlAYZoHZC3KEZ5Sp2WzEhSRd1ceYjaXot-t94Gg',
    owner_answer:
      'Thank you 🙏🙏🙏. Its great to know you and your friends love our restaurant ambience, food and service. Look forward to see you soon.',
    owner_answer_timestamp: '1618832938',
    owner_answer_timestamp_datetime_utc: '04/19/2021 11:48:58',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s104171482675343226151!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618713337',
    review_datetime_utc: '04/18/2021 02:35:37',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/102031071246501264965?hl=en-US',
    autor_name: 'Milind Salokhe',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GjiKiuPscDU-jwzeGGf4d-VMCaDsb4XjmrVLpD9bEc=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text:
      'Very cooperative staff and owner. The dishes are also really nice especially “Fish Tikka”. Very fresh fish and appropriate fry and what a taste.\r\nYou need to book in advance and reach there early to enjoy the riverside ambience. Other food is also good, we have tried non-veg platter and biryani (chicken and mutton), all dishes were good.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GjiKiuPscDU-jwzeGGf4d-VMCaDsb4XjmrVLpD9bEc',
    owner_answer:
      'Thank you Milind 🙏.\r\n\r\nWe are pleased to read you loved our food, Zaika (flavour) and hospitality. We look forward to being at your service again with more great Indian "Zaika\'s" 🙏. See you soon.',
    owner_answer_timestamp: '1619400878',
    owner_answer_timestamp_datetime_utc: '04/26/2021 01:34:38',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s102031071246501264965!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1619359889',
    review_datetime_utc: '04/25/2021 14:11:29',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/108848470020563506842?hl=en-US',
    autor_name: 'madhu bala',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GgeBgAhERSgJCoPP1zLb4yFTwcQhTgaLRNl_mW3TA=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text: 'Food was good but still could have been better for the price they offer...',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GgeBgAhERSgJCoPP1zLb4yFTwcQhTgaLRNl_mW3TA',
    owner_answer:
      "Thank you Madhu Bala ji 🙏and it's great to know you loved our food.\r\n\r\nLook forward to see you again.",
    owner_answer_timestamp: '1620890995',
    owner_answer_timestamp_datetime_utc: '05/13/2021 07:29:55',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s108848470020563506842!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '4',
    review_timestamp: '1620655932',
    review_datetime_utc: '05/10/2021 14:12:12',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/108658958906678315570?hl=en-US',
    autor_name: 'Bhuva Vishal',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJzftshKcbVTSE0L_ygdigipNluKfc7CTVrFpdS1=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Great hospitality...\r\nNice taste of food.\r\n\r\nLittle slow service as food is prepared on order, which is good.\r\n\r\nIf you are not very hungry and time to enjoy dinner then great plt to visit',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJzftshKcbVTSE0L_ygdigipNluKfc7CTVrFpdS1',
    owner_answer:
      'Thank you very much VISHAL BHAI\r\nYour visiting our restaurant we have your feedback we are improving our service please do come back 🙏🙏',
    owner_answer_timestamp: '1611938727',
    owner_answer_timestamp_datetime_utc: '01/29/2021 16:45:27',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s108658958906678315570!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '4',
    review_timestamp: '1611804707',
    review_datetime_utc: '01/28/2021 03:31:47',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/115896572700061991622?hl=en-US',
    autor_name: 'Zhisheng Lee',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GgGS0QjPRzAf17cLUoT_R6usZgzoq3p5gpoOC4=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      "I had the chance to dine in Tandoori Zaika twice and I must say it is indeed an Indian restaurant that I'd recommend for a Chinese/Singaporean to taste the delicious food. The taste was pleasant and fits a Chinese taste and aroma, which previously from other India restaurants I either find them too spicy or the taste is not my fit. I guess it is because our taste buds are different from two different race groups which are normal. You need to try their tandoor chicken and fried vegetables which I enjoyed the most. Also do ask for their dipping sauce, a green slightly spicy and sweet sauce that you can't go without for most of the meat eating. Hoolla! Please visit and support them. Forgotten to mention the service staff, they are excellent as well in serving and listening to your needs.",
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GgGS0QjPRzAf17cLUoT_R6usZgzoq3p5gpoOC4',
    owner_answer:
      "Thank you Zhisheng 🙏 for visiting us again. It's so pleasing to know we could meet you Zaika (flavour) expectations. Its great to read you loved food and service.\r\n\r\nWe look forward to seeing you soon with your friends and dear ones.",
    owner_answer_timestamp: '1619014975',
    owner_answer_timestamp_datetime_utc: '04/21/2021 14:22:55',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s115896572700061991622!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618991015',
    review_datetime_utc: '04/21/2021 07:43:35',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/115349192214564935760?hl=en-US',
    autor_name: 'Heng Tze Lim',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJxsqG3FyIb7_1rV2OtpMlygr9mjVnrMxU3_wCNw=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Love the place. Location is perfect - view of the passing tourist boats on the river and MBS. Food is heavenly. Try the fried onion and chicken tikka masala - you will come back for them. And many other good selections. Highly recommended.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJxsqG3FyIb7_1rV2OtpMlygr9mjVnrMxU3_wCNw',
    owner_answer:
      'Thank you very much 🙏. Its great to hear you loved our place, ambience and food.\r\n\r\nWe look forward to see you soon with your friends and family.',
    owner_answer_timestamp: '1619013208',
    owner_answer_timestamp_datetime_utc: '04/21/2021 13:53:28',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s115349192214564935760!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618978074',
    review_datetime_utc: '04/21/2021 04:07:54',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/102746907271373251267?hl=en-US',
    autor_name: 'Shubhendu Kumar',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GjusM4Iyxqt4ugZZJmoZ3ObrhIDthcAnRDhY_I_QQ=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Excellent service from the staff especially by Raj.\r\nVery much customer centric. We were really happy & satisfied with the overall food quality.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GjusM4Iyxqt4ugZZJmoZ3ObrhIDthcAnRDhY_I_QQ',
    owner_answer: 'Thank you very much sir 🙏🙏\r\nYour visiting our restaurant please do come back.',
    owner_answer_timestamp: '1617433737',
    owner_answer_timestamp_datetime_utc: '04/03/2021 07:08:57',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s102746907271373251267!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1617426653',
    review_datetime_utc: '04/03/2021 05:10:53',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/115197774668224402215?hl=en-US',
    autor_name: 'Tej karan',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GhfC3h9pokORUFX9Ppy2jVFpNAAYH-mRS_dSEvZRg=s120-c-c0x00000000-cc-rp-mo-ba4-br100',
    review_text: 'Good options on infian food ! Great place to hangout',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GhfC3h9pokORUFX9Ppy2jVFpNAAYH-mRS_dSEvZRg',
    owner_answer: 'Thank you Tej 🙏, We look forward to see you soon.',
    owner_answer_timestamp: '1621745893',
    owner_answer_timestamp_datetime_utc: '05/23/2021 04:58:13',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s115197774668224402215!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '4',
    review_timestamp: '1621139184',
    review_datetime_utc: '05/16/2021 04:26:24',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/113514913786203818997?hl=en-US',
    autor_name: 'suma shenoy',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GhScVgr_znMSs7y6jkVPvK1pn1YRJe_1e8yyUAqtg=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Excellent Service and Yummiest food in all of Singapore. I have known Raju for the past 8 years and the one thing he has been consistent about is the quality and taste delivered in the food served. Special mention to the lovely ladies who served us dinner tonight - Ms. Kinosha and Ms. Neha. Thank you and we will definitely be back soon.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GhScVgr_znMSs7y6jkVPvK1pn1YRJe_1e8yyUAqtg',
    owner_answer:
      'Thank you Suma 🙏🙏🙏and its always my & my teams pleasure to be at your service with our Indian Zaika\'s (flavours).\r\n\r\nOne of our core value is "Offering Consistent Great Indian Zaika\'s (flavours)" and when our patrons confirm we are delivering this successfully, me and my team get energised.\r\n\r\nLook forward to seeing you soon and being at your service.\r\n\r\nRegards,\r\n\r\nRaju\r\n(Manager)',
    owner_answer_timestamp: '1618658908',
    owner_answer_timestamp_datetime_utc: '04/17/2021 11:28:28',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s113514913786203818997!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618583234',
    review_datetime_utc: '04/16/2021 14:27:14',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/106374232140237373895?hl=en-US',
    autor_name: 'Rachana Upadhyay',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GjlRzvuuYbtT9WLxTD0h_xrFaz7-rotn0JqDTwCcg=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Awesome view and authentic Indian food with good vegetarian options too 😊',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GjlRzvuuYbtT9WLxTD0h_xrFaz7-rotn0JqDTwCcg',
    owner_answer:
      'Thank you Rachana 🙏. Its great to know you loved the food and Zaika (flavour) . Look forward to seeing you soon.',
    owner_answer_timestamp: '1619014517',
    owner_answer_timestamp_datetime_utc: '04/21/2021 14:15:17',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s106374232140237373895!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618981400',
    review_datetime_utc: '04/21/2021 05:03:20',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/100298371200784305479?hl=en-US',
    autor_name: 'Sudhir Mallegowda',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14Ggcjcy7IPwSXVm_YE_O9Mhhqj94Lxk0NAg0getAzy8=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: '“ Recall as often as you wish amazing dining experience at Tandoori Zaika will never wear out”',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14Ggcjcy7IPwSXVm_YE_O9Mhhqj94Lxk0NAg0getAzy8',
    owner_answer:
      'Thank you Sudhir 🙏for being our patron. We love being at your service and we look forward to seeing you soon 🙏🙏🙏.',
    owner_answer_timestamp: '1619013859',
    owner_answer_timestamp_datetime_utc: '04/21/2021 14:04:19',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s100298371200784305479!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618979164',
    review_datetime_utc: '04/21/2021 04:26:04',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/117136204693879440258?hl=en-US',
    autor_name: 'Arun Pawar',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJxBg_th4mCRuKsp4AVtHzI07oALf4Vkn9dYR45Q=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Nice Indian food. I recommended this Tandoori Zaika😋',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJxBg_th4mCRuKsp4AVtHzI07oALf4Vkn9dYR45Q',
    owner_answer: 'Thanks Arun 🙏for recommending us for great Indian food.  We look forward to seeing you soon 🙏.',
    owner_answer_timestamp: '1620008379',
    owner_answer_timestamp_datetime_utc: '05/03/2021 02:19:39',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s117136204693879440258!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1619883895',
    review_datetime_utc: '05/01/2021 15:44:55',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/107452014239906426997?hl=en-US',
    autor_name: 'Abhishek Upadhyay',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14Ggb1CzPrmWMVMQ07FCVLzwNiRc8yVexrJF2VkDD3PU=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'The food quality is delectable and services provides by all staff are outstanding,especially Kinosha !',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14Ggb1CzPrmWMVMQ07FCVLzwNiRc8yVexrJF2VkDD3PU',
    owner_answer:
      'Thank you Abhishek-ji  🙏\r\nIt is great to know you liked & rated our food and service high. We look forward to seeing you again soon and being at your service.\r\n\r\nRegards,\r\n\r\nRaju\r\n(Manager)\r\n🙏🙏',
    owner_answer_timestamp: '1618452892',
    owner_answer_timestamp_datetime_utc: '04/15/2021 02:14:52',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s107452014239906426997!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '4',
    review_timestamp: '1618404037',
    review_datetime_utc: '04/14/2021 12:40:37',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/116582436554095434990?hl=en-US',
    autor_name: 'Teju Sudhir',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJwn7njzoILVBLEb705A60rKxefZgFDfPNDsFAnF=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Amazing Food and hospitality .. always enjoyed the food to the fullest. Thank you Tandoori Zaika team.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJwn7njzoILVBLEb705A60rKxefZgFDfPNDsFAnF',
    owner_answer:
      'Thank you Teju 🙏. It so fulfilling for us and our staff to know that our guests loved the food and their hospitality. Thanks for being our patron and we look forward to seeing you soon 🙏🙏🙏.',
    owner_answer_timestamp: '1619013757',
    owner_answer_timestamp_datetime_utc: '04/21/2021 14:02:37',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s116582436554095434990!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618978849',
    review_datetime_utc: '04/21/2021 04:20:49',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/112453757986538536015?hl=en-US',
    autor_name: 'Dilip Nair',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJxmGkAoFH2-KLJbwQvdz00B16uquXrRZxJC3mAF=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'The best Indian cuisine in Singapore! My favourite is the Dal & Palak Khichdi. Great service by Raju Bhai & team. Love it!',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJxmGkAoFH2-KLJbwQvdz00B16uquXrRZxJC3mAF',
    owner_answer:
      "Thank you Dilip Bhai 🙏for being our patron  and rating us the Best Indian Cuisine in Singapore.\r\n\r\nIt's great to hear you love the food, Zaika's (flavours) and service (based on one of core values Atithi Devo Bhava [अतिथिदेवो भव:))] .\r\n\r\nWe love being at your service and we look forward to seeing you soon with your family, friends and colleagues  🙏🙏",
    owner_answer_timestamp: '1620007884',
    owner_answer_timestamp_datetime_utc: '05/03/2021 02:11:24',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s112453757986538536015!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1619785107',
    review_datetime_utc: '04/30/2021 12:18:27',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/113355556509567896676?hl=en-US',
    autor_name: 'Suman Sharma',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJzan8LaFCVfAWxZfrqbEr5YTkGODM5OsXixeXxQ=s120-c-c0x00000000-cc-rp-mo-ba4-br100',
    review_text: 'Good food. Reasonable price. Good ambiance. Book in advance.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJzan8LaFCVfAWxZfrqbEr5YTkGODM5OsXixeXxQ',
    owner_answer: 'Thank so much for your visiting our restaurant please do come back 🙏🙏',
    owner_answer_timestamp: '1617339374',
    owner_answer_timestamp_datetime_utc: '04/02/2021 04:56:14',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s113355556509567896676!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '4',
    review_timestamp: '1617338637',
    review_datetime_utc: '04/02/2021 04:43:57',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/111447499314921316987?hl=en-US',
    autor_name: 'Sheetal Chaturvedi',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJzyuFpk6tSGE1xdKoa4S_1kMAJBa2g5tXxUfjLj=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Good Indian food, great service, friendly staff, great ambience. Totally love this place to have a good evening with family and friends.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJzyuFpk6tSGE1xdKoa4S_1kMAJBa2g5tXxUfjLj',
    owner_answer:
      'Thank you very much 🙏🙏\r\nThank you for visiting our restaurant please do come back again\r\nPlease do come back again.',
    owner_answer_timestamp: '1615269533',
    owner_answer_timestamp_datetime_utc: '03/09/2021 05:58:53',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s111447499314921316987!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1615267135',
    review_datetime_utc: '03/09/2021 05:18:55',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/114263592697180708028?hl=en-US',
    autor_name: 'Billa Ranga',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJx5XMZlb3u-euzkWIYYOJwoJZMYfCFsFaURicJk=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Excellent food & amazing ambience!!!\r\n\r\nThanks to kinosha for excellent customer service .',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJx5XMZlb3u-euzkWIYYOJwoJZMYfCFsFaURicJk',
    owner_answer:
      'Thank you for taking time and it is great to know you liked our service, food and ambience. We appreciate you motivating our staff (Kinosha) by mentioning them and referring her good work.\r\n\r\nWe look forward to seeing you again soon and being at your service.\r\n\r\nRegards,\r\n\r\nRaju\r\n(Manager)\r\n🙏🙏',
    owner_answer_timestamp: '1618396504',
    owner_answer_timestamp_datetime_utc: '04/14/2021 10:35:04',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s114263592697180708028!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618321305',
    review_datetime_utc: '04/13/2021 13:41:45',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/110034813868991954743?hl=en-US',
    autor_name: 'Paridhi Agrawal',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14Ggr4WeyjNZm_OpY5tUFPMjYc1-uUpJVCvjWj0tVFQ=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text:
      'Food is good (especially the breads are better than most other indian restaurants) but the service is really slow - took around one hour from the time we ordered!',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14Ggr4WeyjNZm_OpY5tUFPMjYc1-uUpJVCvjWj0tVFQ',
    owner_answer:
      'Mr pharidhi Agrawal\r\nThank you for telling us that the bread was good. Me and my staff are very sorry for the inconvenience we caused you and the other people.\r\nI hope you would take our sincere apologie.\r\nPlease visit our restaurant again.\r\nFrom:Raju(manager)',
    owner_answer_timestamp: '1616333432',
    owner_answer_timestamp_datetime_utc: '03/21/2021 13:30:32',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s110034813868991954743!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '4',
    review_timestamp: '1616205978',
    review_datetime_utc: '03/20/2021 02:06:18',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/115994051413449833643?hl=en-US',
    autor_name: 'Ramshankar Nagendran',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14Ghe67n1L95gY-aJ2MBqdVsN8D8374PkTpMCXs5_oA=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text: 'Good place to gang out. Commentable hospitality by Kinosha.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14Ghe67n1L95gY-aJ2MBqdVsN8D8374PkTpMCXs5_oA',
    owner_answer: 'Thank you 🙏🙏🙏Look forward to see you soon.',
    owner_answer_timestamp: '1618832988',
    owner_answer_timestamp_datetime_utc: '04/19/2021 11:49:48',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s115994051413449833643!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618756288',
    review_datetime_utc: '04/18/2021 14:31:28',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/107966404084844873259?hl=en-US',
    autor_name: 'manoj kumar',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GjMtEVYl4ABV6I_v-OgkklooV3cTcmtpUm_BBgaPg=s120-c-c0x00000000-cc-rp-mo-ba2-br100',
    review_text: 'Nice place and food also🎈',
    review_img_url: 'https://lh5.googleusercontent.com/p/AF1QipMl2QKEmo5heamKIEzcy5M5TF7ryqNqWCnrXYzE',
    owner_answer: 'Mr monoj\r\nThank you very much sir your visiting our restaurant please visit again 🙏🙏',
    owner_answer_timestamp: '1602964179',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:49:39',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s107966404084844873259!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1591365447',
    review_datetime_utc: '06/05/2020 13:57:27',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/105641638900747642818?hl=en-US',
    autor_name: 'Kean Chong Loh',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJwaDsT1qzr3519zFejzigbR7Fpj4kXuigi-la09=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text: 'Good variety of northen Indian food. Good Services',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJwaDsT1qzr3519zFejzigbR7Fpj4kXuigi-la09',
    owner_answer:
      'Thank you very much sir 🙏🙏\r\n\r\nWe appreciate your feedback. We look forward to seeing you again soon and being at your service with more Indian Zaika’s.\r\n\r\nRegards,\r\n\r\nRaju\r\n(Manager)\r\n🙏🙏',
    owner_answer_timestamp: '1618038812',
    owner_answer_timestamp_datetime_utc: '04/10/2021 07:13:32',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s105641638900747642818!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '4',
    review_timestamp: '1617980871',
    review_datetime_utc: '04/09/2021 15:07:51',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/107166026127046941058?hl=en-US',
    autor_name: 'Ashokan Kandiah',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJxWWPRSwX6FtyHBCDxNlKcBS7DbuyePAqYB_OM1=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Excellent location & Great friendly service. Awesome and authentic with choice of variety of Indian vegetarian cuisines. Paneer Pudina Tikka & Dal Tadka stands out. Must visit for Dining with family/friends.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJxWWPRSwX6FtyHBCDxNlKcBS7DbuyePAqYB_OM1',
    owner_answer:
      "Thank you Ashokan 🙏. We strive to provide authentic Indian Zaika and its great to know you loved it. It's great you loved our signature dishes, our location and our service.\r\nWe look forward to seeing you soon with friend / family...",
    owner_answer_timestamp: '1619014722',
    owner_answer_timestamp_datetime_utc: '04/21/2021 14:18:42',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s107166026127046941058!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618981497',
    review_datetime_utc: '04/21/2021 05:04:57',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/115606890072854758061?hl=en-US',
    autor_name: 'margart Yeo',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJxN4wv4nd2RnA3GP2bf1vnj74_2Nj-awQca-z0N=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Ms Kinosha served with passion and recommended naan and chicken tikka masala. Love the spice and great taste!',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJxN4wv4nd2RnA3GP2bf1vnj74_2Nj-awQca-z0N',
    owner_answer:
      'Thank You Margart 🙏🙏and its great to know you loved our speciality food, Zaika (flavour).\r\n\r\nWe look forward to seeing you soon and let you try more of our "Zaika\'s"',
    owner_answer_timestamp: '1618657786',
    owner_answer_timestamp_datetime_utc: '04/17/2021 11:09:46',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s115606890072854758061!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618575856',
    review_datetime_utc: '04/16/2021 12:24:16',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/115920972342095067150?hl=en-US',
    autor_name: 'Darshana Baradia',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJyQ0IvmX4qhBz1bmzX7Cf587nRLxCcclhgDOKpv=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Delicious food and good service by Danu👍🏻😊',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJyQ0IvmX4qhBz1bmzX7Cf587nRLxCcclhgDOKpv',
    owner_answer: 'Thank you very much 🙏 Darshana ji, we look forward to seeing you soon and being at your service.',
    owner_answer_timestamp: '1618542740',
    owner_answer_timestamp_datetime_utc: '04/16/2021 03:12:20',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s115920972342095067150!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '4',
    review_timestamp: '1618464685',
    review_datetime_utc: '04/15/2021 05:31:25',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/104495706674453331926?hl=en-US',
    autor_name: 'Ulrika Mortimer-Schutts',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJwRHkEnsOwVMvnh-06-5aYrZZVR54EzoWBa-XzF=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Had good food!  Danuta was very kind and helpful, thank you!!',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJwRHkEnsOwVMvnh-06-5aYrZZVR54EzoWBa-XzF',
    owner_answer:
      "Thank you very much 🙏🙏 for visiting us.\r\n\r\nIt always pleasure to know our guest's loved our food and service. We appreciate you motivating our staff (Dhanuja) by mentioning her good work. We look forward to seeing you soon and being at your service again with more Indian Zaika’s.\r\n\r\nRegards,\r\n\r\nRaju\r\n(Manager)\r\n🙏🙏",
    owner_answer_timestamp: '1618453190',
    owner_answer_timestamp_datetime_utc: '04/15/2021 02:19:50',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s104495706674453331926!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618410235',
    review_datetime_utc: '04/14/2021 14:23:55',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/105408888830970694632?hl=en-US',
    autor_name: 'Sridhar K',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJyL3AJbY8XqxT23QB4a4PU-0Ft4lqcO9fZeu93y=s120-c-c0x00000000-cc-rp-mo-ba4-br100',
    review_text:
      'Excellent restaurant with good food and also good service.  Kinosha who waited on us was very professional and sweet.  She even offered us a free gulab jamun at the end of our meal.  The vegetable soup, the lacha paratha, paneer tikka masala and the garlic butter naan were all good.  Overall, a wonderful experience!',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJyL3AJbY8XqxT23QB4a4PU-0Ft4lqcO9fZeu93y',
    owner_answer:
      'Thanks Sridhar ji 🙏and its great that you loved all the food ordered, service and hospitality.\r\n\r\nWe look forward to seeing you soon and let you try more of our "Zaika\'s" 🙏',
    owner_answer_timestamp: '1618659134',
    owner_answer_timestamp_datetime_utc: '04/17/2021 11:32:14',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s105408888830970694632!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '4',
    review_timestamp: '1618585772',
    review_datetime_utc: '04/16/2021 15:09:32',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/112535452026064107191?hl=en-US',
    autor_name: 'Amulya MP',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GjI1c9YvkoO6Ql1l5Zzf8nG7tPIPnGhHp33Tdkaow=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Must visit to try out there best tandoor dishes which I have never tasted it before in any other restaurant. Must try there panner kati rolls, fish tikka, palak dal kichadi. Good ambiance by the river view and pricing is also good.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GjI1c9YvkoO6Ql1l5Zzf8nG7tPIPnGhHp33Tdkaow',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s112535452026064107191!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1619069132',
    review_datetime_utc: '04/22/2021 05:25:32',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/109531033371001610200?hl=en-US',
    autor_name: 'Maquiavelo',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GgMJuSYXK8aTDoEqnwdDMcVswMWD-4-uLeY8YLJ-w=s120-c-c0x00000000-cc-rp-mo-ba4-br100',
    review_text: 'I loved the samosa Chaat',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GgMJuSYXK8aTDoEqnwdDMcVswMWD-4-uLeY8YLJ-w',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s109531033371001610200!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1623120670',
    review_datetime_utc: '06/08/2021 02:51:10',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/103572222293082484028?hl=en-US',
    autor_name: 'ManikandaPrabhu M',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14Gj5OhZuKNAXar144T6hHuQAKnQG5P3bvwJa2mc9Ct0=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text: "Very tasty dishes. Didn't get disappointed. Nice staffs. Pocket friendly too.",
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14Gj5OhZuKNAXar144T6hHuQAKnQG5P3bvwJa2mc9Ct0',
    owner_answer: 'Thank you very much sir 🙏\r\nYour visit our restaurant please do come back.',
    owner_answer_timestamp: '1610182770',
    owner_answer_timestamp_datetime_utc: '01/09/2021 08:59:30',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s103572222293082484028!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1610178947',
    review_datetime_utc: '01/09/2021 07:55:47',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/104226468307892955057?hl=en-US',
    autor_name: 'Vanessa Joseph',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJybBP2imp4u6sEZNNrfqlYtkgAS1QBecRgA7Gsf=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Excellent food and ambiance - Dining by the river!',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJybBP2imp4u6sEZNNrfqlYtkgAS1QBecRgA7Gsf',
    owner_answer:
      "Thank you Vanessa 🙏for your review and sharing with us you liked our food and ambience. We look forward to seeing you soon to try more Indian Zaika's (flavour's).",
    owner_answer_timestamp: '1619013645',
    owner_answer_timestamp_datetime_utc: '04/21/2021 14:00:45',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s104226468307892955057!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618978590',
    review_datetime_utc: '04/21/2021 04:16:30',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/115233826810226854735?hl=en-US',
    autor_name: 'Dinesh Lingot',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14Ggv5Vo_5rTGrQAJBPrMly4M7hPTqAN3SNIiViyFcw=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Wonderful dining experience by the river at Boat Quay.\r\nExcellent food.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14Ggv5Vo_5rTGrQAJBPrMly4M7hPTqAN3SNIiViyFcw',
    owner_answer:
      'Thanks Dinesh 🙏. Its so pleasing to know you loved the food and overall dining experience. We look forward to seeing you soon 🙏.',
    owner_answer_timestamp: '1620008262',
    owner_answer_timestamp_datetime_utc: '05/03/2021 02:17:42',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s115233826810226854735!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1619883894',
    review_datetime_utc: '05/01/2021 15:44:54',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/101419005931977857941?hl=en-US',
    autor_name: 'Pavan KK',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GjwgaVjW280N7u2OHqq1vLbh4FA4c0Yml0QyLaorQ=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Kinosha & dhanu really made our hospitality',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GjwgaVjW280N7u2OHqq1vLbh4FA4c0Yml0QyLaorQ',
    owner_answer: 'Thank you very much 🙏🙏 Pavan ji and we look forward to seeing you soon. Have a great day.',
    owner_answer_timestamp: '1618453261',
    owner_answer_timestamp_datetime_utc: '04/15/2021 02:21:01',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s101419005931977857941!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618411890',
    review_datetime_utc: '04/14/2021 14:51:30',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/113783437125932604157?hl=en-US',
    autor_name: 'DFletch',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GhZgU8e8I6hnd5-8nfTj4AVSciFXXEcyGfXRef6p-Y=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Stumbled upon this Gem after work. Had a couple of good food recommendations from Konisha.\r\nI had Paid Chat for starters.\r\n\r\nFish Methi Masala..(must try!!) Paired with Garlic Cheese Naan.\r\n\r\nMasala tea to finish off.\r\n\r\nWonderful dining experience by the river accompanied by Friendly staff❤️❤️❤️\r\n\r\nDefinitely coming back and highly recommend Tandoori Zaika!!',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GhZgU8e8I6hnd5-8nfTj4AVSciFXXEcyGfXRef6p-Y',
    owner_answer:
      'Thank you very much 🙏🙏 for your review and it was our pleasure.\r\n\r\nGreat to know you loved our food, zaika (flavour) and service.  We look forward to seeing you soon and being at your service again with more Indian Zaika’s.\r\n\r\nRegards,\r\n\r\nRaju\r\n(Manager)\r\n🙏🙏',
    owner_answer_timestamp: '1618395890',
    owner_answer_timestamp_datetime_utc: '04/14/2021 10:24:50',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s113783437125932604157!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618316694',
    review_datetime_utc: '04/13/2021 12:24:54',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/114607878980702131487?hl=en-US',
    autor_name: 'faisal parvez',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJxJ_gibIIKzSrr-qYC8KpCOgQEucrx1ihVEY2BV=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Really great food... awesome ambience especially if you manage to can get space close to the river ... booking a day before is recommended. suggested dishes are mutton chops, chilli chicken,  dal tadka which are must try’s.... butter naan is simply awesome. The daal tadka is out of the world too with Zeera rice. Sitting by the bar counter listening to some soft music and enjoying light snacks and drinks is also a good idea on a quiet evening. The manager there is always welcoming of any small customisations you need with your dishes like making it extra spicy or if you feel like habits dal khuchdi which isn’t on their menu they ensure they go the extra mile to make it happen too. All in all the best joints in ten patch of boat quay Indian restaurants...',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJxJ_gibIIKzSrr-qYC8KpCOgQEucrx1ihVEY2BV',
    owner_answer:
      'Thank you for detailed review and it means a lot of our team. We look forward to seeing you soon to be able to service you and let you try more of our "Zaika\'s".',
    owner_answer_timestamp: '1617764540',
    owner_answer_timestamp_datetime_utc: '04/07/2021 03:02:20',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s114607878980702131487!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1617708040',
    review_datetime_utc: '04/06/2021 11:20:40',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/110023729584324764409?hl=en-US',
    autor_name: 'Madhu Hosmane',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJwbIhIsyz-akTL_wdffwMXFF2BO3ZsE1hMtEi1s=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Great food with the choice of variety of dishes for family lunch/dinner or with friends. Service is great and please do book table for weekends to avoid waiting time! Raju as usual is an amazing host to make you feel special with his hospitality on every visit!',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJwbIhIsyz-akTL_wdffwMXFF2BO3ZsE1hMtEi1s',
    owner_answer: 'Thank you very much sir always your awesome 🙏🙏\r\nStay safe stay healthy. 💪',
    owner_answer_timestamp: '1604334361',
    owner_answer_timestamp_datetime_utc: '11/02/2020 16:26:01',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s110023729584324764409!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1604331542',
    review_datetime_utc: '11/02/2020 15:39:02',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/110519466689090066591?hl=en-US',
    autor_name: 'Pragya Bothra',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJyQyWSRM5W47OhK4GQvJPT2XVEx8RwjC8W7WWw=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Good food, good ambiance & good staff',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJyQyWSRM5W47OhK4GQvJPT2XVEx8RwjC8W7WWw',
    owner_answer: 'Thank you very much 🙏🙏\r\nYour visiting our restaurant please do come back.',
    owner_answer_timestamp: '1617433800',
    owner_answer_timestamp_datetime_utc: '04/03/2021 07:10:00',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s110519466689090066591!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '4',
    review_timestamp: '1617426602',
    review_datetime_utc: '04/03/2021 05:10:02',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/117965502201895457369?hl=en-US',
    autor_name: 'Tracy Lim',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJyQ13daYgyyTmooN2U8eCJ8wM2GtSl5H4aDiYRl=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Recommend this place in boat quay. Serve very nice Indian food. I love the service as well. kudos to Raju.\r\nChicken tikka, chicken 65, samosa chat are must order',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJyQ13daYgyyTmooN2U8eCJ8wM2GtSl5H4aDiYRl',
    owner_answer: 'Thank you very much 🙏🙏\r\nYour visit our restaurant please do come back...',
    owner_answer_timestamp: '1610213440',
    owner_answer_timestamp_datetime_utc: '01/09/2021 17:30:40',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s117965502201895457369!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1610201161',
    review_datetime_utc: '01/09/2021 14:06:01',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/117866122875830044541?hl=en-US',
    autor_name: 'Sonam Gaur',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJzBzOujX0BM4Ze54qgy9p8AdTBoAIQa41kpW8Rn=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text: 'Nice indian food service was also good.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJzBzOujX0BM4Ze54qgy9p8AdTBoAIQa41kpW8Rn',
    owner_answer:
      'Thank you very much your visiting our restaurant please do come back 🙏🙏\r\nThank you you for your five star.',
    owner_answer_timestamp: '1616084264',
    owner_answer_timestamp_datetime_utc: '03/18/2021 16:17:44',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s117866122875830044541!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1616062784',
    review_datetime_utc: '03/18/2021 10:19:44',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/113492121957586842403?hl=en-US',
    autor_name: 'Aman Sharma',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJz824oPzY7xuoFyzuLzgfEy9Jeib9TNZcXHVng5=s120-c-c0x00000000-cc-rp-mo-ba2-br100',
    review_text: 'Excellent Indian food! The paneer tikka and chilli paneer stand out!',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJz824oPzY7xuoFyzuLzgfEy9Jeib9TNZcXHVng5',
    owner_answer: 'Thank you very much AMAN BHAI your visiting our restaurant please do come back. 🙏🙏',
    owner_answer_timestamp: '1615914983',
    owner_answer_timestamp_datetime_utc: '03/16/2021 17:16:23',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s113492121957586842403!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1615880077',
    review_datetime_utc: '03/16/2021 07:34:37',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/110645412175387064265?hl=en-US',
    autor_name: 'Clayton Chua',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14Gi2FeSKFpMKjgoP7ZsXm4Pt9l7RmwFI6ISFhdkMfg=s120-c-c0x00000000-cc-rp-mo-ba6-br100',
    review_text: 'Lovely ambiance and food! Naans were tasty and great! Very good service rendered.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14Gi2FeSKFpMKjgoP7ZsXm4Pt9l7RmwFI6ISFhdkMfg',
    owner_answer: 'thank you very much sir your visit our restaurant 🙏🙏',
    owner_answer_timestamp: '1608431736',
    owner_answer_timestamp_datetime_utc: '12/20/2020 02:35:36',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s110645412175387064265!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1608385856',
    review_datetime_utc: '12/19/2020 13:50:56',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/117244856831340949042?hl=en-US',
    autor_name: 'Vijayakumar Venu',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJzIZ8PTQDJK-Swiu_NutZPHFn8W6y2vo7wJllmm=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Fantastic ambience, excellent service and delicious food!!!',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJzIZ8PTQDJK-Swiu_NutZPHFn8W6y2vo7wJllmm',
    owner_answer:
      'Thank you very much sir 🙏🙏\r\n\r\nGood to know you loved our food and service. We look forward to seeing you again soon and being at your service with more Zaika’s.\r\n\r\nRegards,\r\n\r\nRaju\r\n(Manager)\r\n🙏🙏',
    owner_answer_timestamp: '1618206007',
    owner_answer_timestamp_datetime_utc: '04/12/2021 05:40:07',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s117244856831340949042!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618198313',
    review_datetime_utc: '04/12/2021 03:31:53',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/105906290977731135213?hl=en-US',
    autor_name: 'nitesh surana',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJztssIc5dfI2MU4mAnKgaERVxQLX9BWlEYsDnlm=s120-c-c0x00000000-cc-rp-mo-ba2-br100',
    review_text:
      'The riverside seating and Paneer Pudina tikka was awesome. Navratan korma was very good. Veg pulao was decent, a little bit of salt and chilli might have made it even better. Nice place and helpful staff.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJztssIc5dfI2MU4mAnKgaERVxQLX9BWlEYsDnlm',
    owner_answer:
      'Thank you very much sir 🙏\r\nYour visiting our restaurant\r\nAlso we teck your your feedback\r\nPlease do come back again.',
    owner_answer_timestamp: '1615111369',
    owner_answer_timestamp_datetime_utc: '03/07/2021 10:02:49',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s105906290977731135213!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1615044780',
    review_datetime_utc: '03/06/2021 15:33:00',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/110352553911563696723?hl=en-US',
    autor_name: 'Shrikant Saswade',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GhTBjWOKnWejsE3SjuiGeRh8MiykdDIX3lYrydz=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Very good hotel. Food was tasty and ambience is very good...',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GhTBjWOKnWejsE3SjuiGeRh8MiykdDIX3lYrydz',
    owner_answer:
      'Hi Shrikantji,\r\n\r\nThank you, we look forward to seeing you again soon and being at your service.\r\n\r\nRegards,\r\n\r\nRaju\r\n(Manager)\r\n🙏🙏',
    owner_answer_timestamp: '1618205333',
    owner_answer_timestamp_datetime_utc: '04/12/2021 05:28:53',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s110352553911563696723!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '4',
    review_timestamp: '1618128321',
    review_datetime_utc: '04/11/2021 08:05:21',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/102385651312891470257?hl=en-US',
    autor_name: 'VINAY NAIDU K',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GjnoLmmfElJ3B-4QqG-Zw0jK9hFVapgyucmimPYTA=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Good ambience and a lovely staff who makes the experience very better.I love fish tikka and chicken 65 personally.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GjnoLmmfElJ3B-4QqG-Zw0jK9hFVapgyucmimPYTA',
    owner_answer: 'Thank you very much 🙏your visit our restaurant please visit once again 🙏🙏',
    owner_answer_timestamp: '1611268036',
    owner_answer_timestamp_datetime_utc: '01/21/2021 22:27:16',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s102385651312891470257!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1611224971',
    review_datetime_utc: '01/21/2021 10:29:31',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/103899221644828625843?hl=en-US',
    autor_name: 'Rajesh Suguru',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GhsXv_DmclsL1-JsRiMZGAjSkPWEzJtjJ6899uK9Q=s120-c-c0x00000000-cc-rp-mo-ba6-br100',
    review_text:
      'Geat place and nice food at river side with elegant view. Great place for team outing and parties. YOu can have great time with the team .',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GhsXv_DmclsL1-JsRiMZGAjSkPWEzJtjJ6899uK9Q',
    owner_answer: 'Mr rajesh suguru\r\nthank you very much your visit our restaurant 🙏🙏',
    owner_answer_timestamp: '1608431890',
    owner_answer_timestamp_datetime_utc: '12/20/2020 02:38:10',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s103899221644828625843!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1608385251',
    review_datetime_utc: '12/19/2020 13:40:51',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/108697914559486969371?hl=en-US',
    autor_name: 'Ankit Gupta',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJzm46Yw5KOL2OwtUiFT4dg9s68oLEA5XpcMDkXH=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Nice Food. Good service. Gentle Staff.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJzm46Yw5KOL2OwtUiFT4dg9s68oLEA5XpcMDkXH',
    owner_answer: 'Thank u Ankit for 5 star rating',
    owner_answer_timestamp: '1613565674',
    owner_answer_timestamp_datetime_utc: '02/17/2021 12:41:14',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s108697914559486969371!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1613564748',
    review_datetime_utc: '02/17/2021 12:25:48',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/117943198099002781375?hl=en-US',
    autor_name: 'Ravi Shetty',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14Giv24HkFGzFRspdzXhX-Mb70dm_dQYGjrJBbCa4_HQ=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Their timing is different in Google and after going we find it closed... or lunch hour over\r\n\r\n2 times faced the issue',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14Giv24HkFGzFRspdzXhX-Mb70dm_dQYGjrJBbCa4_HQ',
    owner_answer:
      'Dear Mr. Ravi Shetty,  Due to Hari RayaPuasa / Eid al-Fitr and Singapore Public Holiday, we are closed for Lunch on 13-May-2021 when you mentioned you visited.\r\n\r\nSorry for the inconvenience caused 🙏.',
    owner_answer_timestamp: '1620890879',
    owner_answer_timestamp_datetime_utc: '05/13/2021 07:27:59',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s117943198099002781375!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '1',
    review_timestamp: '1620889355',
    review_datetime_utc: '05/13/2021 07:02:35',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/108091512301584575958?hl=en-US',
    autor_name: 'Pratik Padalia',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJx0IpwxZjdGx9Hob9Z0t9L9PAr6lS5MAvM4Cq3P=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Good location. Starters are okay, main course was good. Could be better 👍🏻',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJx0IpwxZjdGx9Hob9Z0t9L9PAr6lS5MAvM4Cq3P',
    owner_answer: 'Thank you very much sir your visit our restaurant we have your feedback doit better 🙏🙏',
    owner_answer_timestamp: '1608431625',
    owner_answer_timestamp_datetime_utc: '12/20/2020 02:33:45',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s108091512301584575958!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '3',
    review_timestamp: '1605272402',
    review_datetime_utc: '11/13/2020 13:00:02',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/103368125790730724201?hl=en-US',
    autor_name: 'Nilesh Jadhav',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GhTe4lYa2fpXHNG-MvFxUJGsEBjWcmg_6AlGKsIbJI=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text:
      'Great place with amazing ambience by the river and mouth watering delicious Indian food. Choice of good whiskeys, beers and wine too.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GhTe4lYa2fpXHNG-MvFxUJGsEBjWcmg_6AlGKsIbJI',
    owner_answer: 'Thank you so much 🙏🙏\r\nNilesh bhai\r\nPlease do come back. Again.',
    owner_answer_timestamp: '1603785167',
    owner_answer_timestamp_datetime_utc: '10/27/2020 07:52:47',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s103368125790730724201!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1603632446',
    review_datetime_utc: '10/25/2020 13:27:26',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/113797628842202804357?hl=en-US',
    autor_name: 'Soham Nath',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJxGsn8QU16sOD69kfzYXKosVFK2afswvVcYSSYZ=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      "Overall, Tandoori Zaika had good food and selection of food. We ordered Garlic Naan, Tandoori Stuffed Paratha, Kaju Masala and Samosa Chaat. The taste of the food was really good. The Kaju Masala was something new and interesting but can be quite filling. The Samosa Chaat was something different compared to the other restaurants.\r\n\r\nWhat led down us was the waiting time and late notification. We had to wait for more than one hour for the food. We waited for the Veg Seekh Kebab but after one hour due to internal staff miscommunication, they told us they can't prepare the Veg Seekh Kebab. Hence they asked us if we would order something else. We ordered Hara Bhara Kebab. We asked the staff many times about the Veg Seekh Kebab and asked for the main course to be served first.\r\n\r\nProbably due to the new experience of on order food, we were not able to bear with the waiting time. Nevertheless, the staff were hospitalable especially for the guy in orange shirt and the lady with the hand tattoo. The guy in orange shirt was the one who told us that due to the staff miscommunication, they couldn't serve Veg Seekh Kebab but I could sense he was apologetic. It was no fault of his. In fact to make up for the delay, they offered us free gulab jamun.\r\n\r\nAll in all, the food was good. I would suggest to book first and inform them to prepare food if you cant wait for long time.",
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJxGsn8QU16sOD69kfzYXKosVFK2afswvVcYSSYZ',
    owner_answer:
      'Mr soham nath\r\nThank you very much your visiting our restaurant we are truly sorry about that our mistakes we have your feedback we are doing better. Please do come back again.',
    owner_answer_timestamp: '1616333824',
    owner_answer_timestamp_datetime_utc: '03/21/2021 13:37:04',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s113797628842202804357!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '3',
    review_timestamp: '1613224782',
    review_datetime_utc: '02/13/2021 13:59:42',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/111391716406243815677?hl=en-US',
    autor_name: 'Sanjay Kumar',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GiNxMIcJfvmsEX6m1-UsGIaPCEnwdRpk8zqikF6oA=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Delicious food..\r\nFriendly staff (Raju bhai & Co.)..\r\nPicturesque background..\r\nBustling crowd in front...\r\nGrand recipe for quality timeout..\r\nDo try once if u r in Singapore. U will love to come again for sure..',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GiNxMIcJfvmsEX6m1-UsGIaPCEnwdRpk8zqikF6oA',
    owner_answer: 'Thank you very much bhai 🙏\r\nYour visit our restaurant please do come back.',
    owner_answer_timestamp: '1608990952',
    owner_answer_timestamp_datetime_utc: '12/26/2020 13:55:52',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s111391716406243815677!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1608983675',
    review_datetime_utc: '12/26/2020 11:54:35',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/116923781693403779166?hl=en-US',
    autor_name: 'Ravindra Adiga',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJwsmsH1fHCKIallN8T3q03xiXP-lr3fsCMhsypB=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Good location, great ambience and very good food :)',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJwsmsH1fHCKIallN8T3q03xiXP-lr3fsCMhsypB',
    owner_answer: 'thank you very much sir your visiting our restaurant please do come back again 🙏🙏',
    owner_answer_timestamp: '1611682549',
    owner_answer_timestamp_datetime_utc: '01/26/2021 17:35:49',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s116923781693403779166!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1611673401',
    review_datetime_utc: '01/26/2021 15:03:21',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/104523537264981726542?hl=en-US',
    autor_name: 'Charu Attri',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GgTgTLqn71ywpvUckGHXlWgOt0MhQrYFeYzSpYg0bk=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Very nice place by the river, good Indian food. We thoroughly enjoyed the chicken tikka and mutton biryani . Must visit if ur in the neighbourhood. Raju was a very pleasant server, a very hospitable person.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GgTgTLqn71ywpvUckGHXlWgOt0MhQrYFeYzSpYg0bk',
    owner_answer: 'Thank you very much 🙏\r\nYour visit our restaurant please do come back..',
    owner_answer_timestamp: '1611267966',
    owner_answer_timestamp_datetime_utc: '01/21/2021 22:26:06',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s104523537264981726542!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1611233812',
    review_datetime_utc: '01/21/2021 12:56:52',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/107116693510370575446?hl=en-US',
    autor_name: 'Manjunath T S',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GisGpmuLW6YM6NKrBghAKdOadicHPaXBJxWXyjGZg=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Excellent service and great food',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GisGpmuLW6YM6NKrBghAKdOadicHPaXBJxWXyjGZg',
    owner_answer: 'Thank you very much 🙏. We look forward to seeing you soon...',
    owner_answer_timestamp: '1619012910',
    owner_answer_timestamp_datetime_utc: '04/21/2021 13:48:30',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s107116693510370575446!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1618978070',
    review_datetime_utc: '04/21/2021 04:07:50',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/106670127659189109665?hl=en-US',
    autor_name: 'Anand Sonar',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14Gg5F4buSjL7-W0H3TkB03HNODokz65BheJbiaZBZyg=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text:
      'Fav choice for authentic north indian food, must tries chicken tikka biryani, tava fish, palak paneer to name a few... Good view, friendly staff a place for friends and family both.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14Gg5F4buSjL7-W0H3TkB03HNODokz65BheJbiaZBZyg',
    owner_answer:
      'Anand bhai\r\nThank you very much 🙏\r\nYour visiting our restaurant please do come back again\r\nThank you very much 🙏',
    owner_answer_timestamp: '1612145705',
    owner_answer_timestamp_datetime_utc: '02/01/2021 02:15:05',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s106670127659189109665!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1612095183',
    review_datetime_utc: '01/31/2021 12:13:03',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/105995633341761602290?hl=en-US',
    autor_name: 'Rajesh M',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJz9b2BBL_OoRYLj5kxn-_yINY3drsXtWdFvr9dO=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text:
      'Brilliant food, extremely tasty Indian and Indian Chinese cuisine, fabulous views , nice ambience,  friendly staff, best place for a beautiful time out and food',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJz9b2BBL_OoRYLj5kxn-_yINY3drsXtWdFvr9dO',
    owner_answer: 'Thank you very much sir your visiting our restaurant its plesent you serve please do come back 🙏🙏',
    owner_answer_timestamp: '1612145552',
    owner_answer_timestamp_datetime_utc: '02/01/2021 02:12:32',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s105995633341761602290!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1612102983',
    review_datetime_utc: '01/31/2021 14:23:03',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/107738844809055609455?hl=en-US',
    autor_name: 'Abhijeet Vijayvergiya',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GjbH9jvVFBtdx8DLzJ_yRyV-iBYE99PaHPhXmOcNg=s120-c-c0x00000000-cc-rp-mo-ba4-br100',
    review_text:
      "I loved their Boon tat outlet and was very happy to see that now they have one at Boat quay too.\r\n\r\nGreat service, great food. Can't got wrong here.",
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GjbH9jvVFBtdx8DLzJ_yRyV-iBYE99PaHPhXmOcNg',
    owner_answer: 'Thank you very much bhai your visiting our restaurant please give me a chance to serve again 🙏🙏',
    owner_answer_timestamp: '1602962666',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:24:26',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s107738844809055609455!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1599374140',
    review_datetime_utc: '09/06/2020 06:35:40',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/104994252597209501536?hl=en-US',
    autor_name: 'vikas naudiyal',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJzPI1p16XJXoyxZgAMszzkZ9WeFq1ZA0zNkQHQ-=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'It was a wonderful experience to dine at Tandoori Zaika!!',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJzPI1p16XJXoyxZgAMszzkZ9WeFq1ZA0zNkQHQ-',
    owner_answer: 'Thank you very much VIKASH BHAI your visiting our restaurant please do come back.. 🙏🙏',
    owner_answer_timestamp: '1615728215',
    owner_answer_timestamp_datetime_utc: '03/14/2021 13:23:35',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s104994252597209501536!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1615727232',
    review_datetime_utc: '03/14/2021 13:07:12',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/117826601274684909303?hl=en-US',
    autor_name: 'Yogita Dhingra',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14Ghd4pIUELz-PjKWOUU56_-7yofhWHqDStQtHSXBB8o=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text:
      'We decided to go to this restaurant and happy that we selected this one. Food was delicious, especially their tandoori Fish, chicken tikka and Pudina paratha. Service was very good and Staff was very courteous.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14Ghd4pIUELz-PjKWOUU56_-7yofhWHqDStQtHSXBB8o',
    owner_answer: 'thank you very much 🙏\r\nYour visit our restaurant.\r\nPlease do come back..',
    owner_answer_timestamp: '1607618755',
    owner_answer_timestamp_datetime_utc: '12/10/2020 16:45:55',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s117826601274684909303!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1607599628',
    review_datetime_utc: '12/10/2020 11:27:08',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/108249817739756538032?hl=en-US',
    autor_name: 'Anjali Das',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJyHN3qBG1eXVPHtD6BjGxzZV5V0R0z6_Uaa8TA1=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Tried both veg &non-veg both were really good loved it. Desert is also good.Enjoyed the food very much',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJyHN3qBG1eXVPHtD6BjGxzZV5V0R0z6_Uaa8TA1',
    owner_answer: 'Thank you very much your visiting our restaurant 🙏🙏',
    owner_answer_timestamp: '1602960874',
    owner_answer_timestamp_datetime_utc: '10/17/2020 18:54:34',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s108249817739756538032!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1601996490',
    review_datetime_utc: '10/06/2020 15:01:30',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/113903705741733548894?hl=en-US',
    autor_name: 'Rohit Dhawan',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GgJtGJYtvfpCzLPU-nrwjHWHVKZ0p-zW5vRZ9pOOQ=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text:
      'I went to this place with a 50% off eatigo voucher and still did not find it value for my money. Food was pretty average and so was the ambience. The only good thing was the sea breeze next to the river. This restaurant is as traditional as can be abd I encourage the owner to apply some creativity to the decor and to the cuisine. I ordered a daal makhani which came warm (meant to be piping hot) and had to be topped with salt to make it palatable. The mutton seekh kebab wasn\'t worth the 25 min wait - it had artificial colour, was seemingly bland and texture was not what you would expect from a fresh tandoor.\r\nLast part of my order was "paid onion salad" which was a novice job with no novelty. It is days like these when you think you shouldn\'t rely too much on those Google reviews...',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GgJtGJYtvfpCzLPU-nrwjHWHVKZ0p-zW5vRZ9pOOQ',
    owner_answer:
      'Thank you very much for visiting our restaurant 🙏, it was our pleasure serving you.\r\nSorry for the trouble caused , we will take your feedback into our account and improve our service so that it meets your needs\r\n\r\nPlease do accept our sincere apologies 🙏',
    owner_answer_timestamp: '1609180559',
    owner_answer_timestamp_datetime_utc: '12/28/2020 18:35:59',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s113903705741733548894!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '1',
    review_timestamp: '1609145080',
    review_datetime_utc: '12/28/2020 08:44:40',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/103911386534077581517?hl=en-US',
    autor_name: 'Chaithanya Ramanatha',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GjBIcz18jPfMaD11Y0N_g4SScowY8z4UF3HQtKBWw=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Service is good and staff take care of customers very well. Good Vegetarian food. Must have "Signature Kichidi".',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GjBIcz18jPfMaD11Y0N_g4SScowY8z4UF3HQtKBWw',
    owner_answer: 'Thank you very much sir your visiting our restaurant and always your sporting very kind 🙏🙏',
    owner_answer_timestamp: '1602962390',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:19:50',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s103911386534077581517!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1600228684',
    review_datetime_utc: '09/16/2020 03:58:04',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/111886556088145705066?hl=en-US',
    autor_name: 'Snehil Jain',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GirA8Qti531hFbojD0ejzSiva7Ilyek5XIhroMNHw=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Came to know about this place recently and to my surprise the food was pretty amazing.\r\n\r\nWill highly recommend for Authentic Indian food lovers.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GirA8Qti531hFbojD0ejzSiva7Ilyek5XIhroMNHw',
    owner_answer: 'Thank you very much 🙏🙏\r\nSnehil bhai please do come back..',
    owner_answer_timestamp: '1603725857',
    owner_answer_timestamp_datetime_utc: '10/26/2020 15:24:17',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s111886556088145705066!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1603721973',
    review_datetime_utc: '10/26/2020 14:19:33',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/106440963823450062610?hl=en-US',
    autor_name: 'Andy Doggett',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJzXW_hPDnkulmllJ1ft3QWoC9gakR63a-XUWm9I=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Friendly service but so slow. After waiting almost 1 hour the wrong dish arrived. Apology offered but the wait and the mix up meant a poor experience.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJzXW_hPDnkulmllJ1ft3QWoC9gakR63a-XUWm9I',
    owner_answer:
      'Mr Andy doggett\r\nThank you very much your visiting our restaurant\r\nSorry about that please visit again we know our mistakes\r\nWe are do our best 🙏🙏',
    owner_answer_timestamp: '1602962204',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:16:44',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s106440963823450062610!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '3',
    review_timestamp: '1600524897',
    review_datetime_utc: '09/19/2020 14:14:57',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/104844247443810065257?hl=en-US',
    autor_name: 'Ram Jagtap',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJzYO7dqaQ_6M_CzuWrjncakt-QaliKPc_12iE31=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Right Place to eat Indian foods.  Having facility to eat inside and outside. staff is very nice and cook is awesome.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJzYO7dqaQ_6M_CzuWrjncakt-QaliKPc_12iE31',
    owner_answer:
      'Thank you very much sir your visiting our restaurant iam\r\nWe are afrsyt thank you your saport 🙏🙏',
    owner_answer_timestamp: '1602961030',
    owner_answer_timestamp_datetime_utc: '10/17/2020 18:57:10',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s104844247443810065257!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1602924358',
    review_datetime_utc: '10/17/2020 08:45:58',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/112915952792137965196?hl=en-US',
    autor_name: 'Laxman Muthiah',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJwLR8enWuxXu5TrMan7DP963LYPyHJc24QAElXt=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Food is very nice.  Especially Fish Tikka.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJwLR8enWuxXu5TrMan7DP963LYPyHJc24QAElXt',
    owner_answer: 'Thank you very much sir 🙏\r\nYour visit our restaurant please do come again..',
    owner_answer_timestamp: '1608744497',
    owner_answer_timestamp_datetime_utc: '12/23/2020 17:28:17',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s112915952792137965196!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1608741657',
    review_datetime_utc: '12/23/2020 16:40:57',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/117071857595458689167?hl=en-US',
    autor_name: 'BalaChander Manthena',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GibG8i1cQdNyR7EVWm7N_VirP8c7SOeo4m1KoyRTQ=s120-c-c0x00000000-cc-rp-mo-ba5-br100',
    review_text: 'Taste was awesome.... nice place.... only thing is naan got cold v.soon',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GibG8i1cQdNyR7EVWm7N_VirP8c7SOeo4m1KoyRTQ',
    owner_answer: 'Thanku very much to visit our restaurant. We have your feedback and do it better.',
    owner_answer_timestamp: '1607356627',
    owner_answer_timestamp_datetime_utc: '12/07/2020 15:57:07',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s117071857595458689167!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '4',
    review_timestamp: '1607348419',
    review_datetime_utc: '12/07/2020 13:40:19',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/112747120480707710161?hl=en-US',
    autor_name: 'SACHIN KUMAR',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GiO0Lfi8uBaMH-fLOH_RrSCeiEzkkgclbFXnJbBfA=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Excellent location,  very good service and good food. Must visit place',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GiO0Lfi8uBaMH-fLOH_RrSCeiEzkkgclbFXnJbBfA',
    owner_answer:
      'Thank you very much sir 🙏\r\nYour visit our restaurant my pleasure to serve please do come again. 👌',
    owner_answer_timestamp: '1609557787',
    owner_answer_timestamp_datetime_utc: '01/02/2021 03:23:07',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s112747120480707710161!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1609540708',
    review_datetime_utc: '01/01/2021 22:38:28',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/114102534258190098998?hl=en-US',
    autor_name: 'Mark Lee',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GiHTXaFbjVdnuX5wJz_h8zYzauYCL60xOhExGY47w=s120-c-c0x00000000-cc-rp-mo-ba6-br100',
    review_text: 'Super good and most importantly AUTHENTIC Indian Food with a wonderful view of the Singapore River',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GiHTXaFbjVdnuX5wJz_h8zYzauYCL60xOhExGY47w',
    owner_answer: 'Thank you very much sir\r\nPlease do come back 🙏',
    owner_answer_timestamp: '1604492286',
    owner_answer_timestamp_datetime_utc: '11/04/2020 12:18:06',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s114102534258190098998!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1604492014',
    review_datetime_utc: '11/04/2020 12:13:34',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/117709508459250050764?hl=en-US',
    autor_name: 'Akshaya Kumar',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GgjA1PhN9EuUfU1iHNCaT5E0pRLtBJWxWsggwJDjw=s120-c-c0x00000000-cc-rp-mo-ba4-br100',
    review_text:
      'Great tasting Indian food with a decent selection of drinks. Prices are cheap considering the location. The place is frequented by repeat customers thanks to the efforts of the friendly owner Raju.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GgjA1PhN9EuUfU1iHNCaT5E0pRLtBJWxWsggwJDjw',
    owner_answer:
      'Mr AKSHAY KUMAR\r\nthank you very much your visiting our restaurant please give me a chance to serve again 🙏🙏',
    owner_answer_timestamp: '1602963265',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:34:25',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s117709508459250050764!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1597579063',
    review_datetime_utc: '08/16/2020 11:57:43',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/116790843019068850333?hl=en-US',
    autor_name: 'Abhinandan R',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJwfRJYtx4bykA5R16s1jdmoaCGAGtFCvaN2QhBK=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Awesome ambience and Mr Raju is the man .Good food and great place for friends and family.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJwfRJYtx4bykA5R16s1jdmoaCGAGtFCvaN2QhBK',
    owner_answer:
      'Thank you bhai!  Apart from me the entire team of Tandoori Zaika is ready to serve customers needs..  Hope to see you again with your family and friends...  Till then take care and stay safe!',
    owner_answer_timestamp: '1604320573',
    owner_answer_timestamp_datetime_utc: '11/02/2020 12:36:13',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s116790843019068850333!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1604319587',
    review_datetime_utc: '11/02/2020 12:19:47',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/115201911131634681551?hl=en-US',
    autor_name: 'Sidharth Bagai',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GjFHLQLpIjWLSSdBmOTAO84CYRtpE0IbkEvECmA=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Delicious food at a fabulous location! You can customize the food as per your taste and even request for mirchi parantha (can’t get anywhere else in Singapore), Raju (the owner) ensures to serve custom requests if there is ample time and less crowd.\r\nI have been to his restaurant since 2014 and never found any compromise in quality or taste of food. One must visit this restaurant!',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GjFHLQLpIjWLSSdBmOTAO84CYRtpE0IbkEvECmA',
    owner_answer: 'Sidharth bhai\r\nThank you very much 🙏🙏\r\nPlease do come back.',
    owner_answer_timestamp: '1604645274',
    owner_answer_timestamp_datetime_utc: '11/06/2020 06:47:54',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s115201911131634681551!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1604643198',
    review_datetime_utc: '11/06/2020 06:13:18',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/106462571361962054154?hl=en-US',
    autor_name: 'Vijayant Kumar Singh',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GhhZDHplEOi8Q2A7GENQdoeBrZ9GVVDsxKIazBEoA=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'If you are looking for really good Indian food, with good variety in appetizers and main course then this place is one of the best options in Singapore.\r\n\r\nDo order their dal-khichdi. Its not in the menu, but can be requested separately.\r\n\r\nOverall, really good food and awesome service.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GhhZDHplEOi8Q2A7GENQdoeBrZ9GVVDsxKIazBEoA',
    owner_answer: 'Mr Vijay Kumar sing\r\nThank you very much your visiting our restaurant thank you 🙏🙏',
    owner_answer_timestamp: '1602961664',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:07:44',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s106462571361962054154!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1601479643',
    review_datetime_utc: '09/30/2020 15:27:23',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/104764099674393817966?hl=en-US',
    autor_name: 'Pooja Prashant Daivajna',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GgeSzJeq857RPTPgHN-ZB_R9CeLNFMa7xXP-JzSFg=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Last week we had been to this lovely place , i really was very much pleased with the hospitality and food.The tandoor items were just mind blowing..we are already keen to revisit this place.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GgeSzJeq857RPTPgHN-ZB_R9CeLNFMa7xXP-JzSFg',
    owner_answer: ',thank you very much your visiting our restaurant and your sporting always very kind 🙏🙏',
    owner_answer_timestamp: '1602962325',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:18:45',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s104764099674393817966!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1600235553',
    review_datetime_utc: '09/16/2020 05:52:33',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/103659711814417673494?hl=en-US',
    autor_name: 'Woz T',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJwwb-9e0maLrpeki-ZOZ84pxvaFR-ElSnUJjsPBZQ=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text: "Amazing service and some of the most delicious Indian food I've ever tasted. 6 stars!",
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJwwb-9e0maLrpeki-ZOZ84pxvaFR-ElSnUJjsPBZQ',
    owner_answer: 'Thank you very much sir 🙏🙏\r\nPlease do come again..',
    owner_answer_timestamp: '1607320765',
    owner_answer_timestamp_datetime_utc: '12/07/2020 05:59:25',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s103659711814417673494!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1607315889',
    review_datetime_utc: '12/07/2020 04:38:09',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/107588254470963072747?hl=en-US',
    autor_name: 'V V',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJxO18pCg2mKqce9LVp9zc_MPRA5olPMeVApJuIi=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Great food, nice place! Specially the person there Mr Ashok Kumar 👍👍',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJxO18pCg2mKqce9LVp9zc_MPRA5olPMeVApJuIi',
    owner_answer: 'Thank you very much sir 🙏🙏\r\nPlease do come back..',
    owner_answer_timestamp: '1604840037',
    owner_answer_timestamp_datetime_utc: '11/08/2020 12:53:57',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s107588254470963072747!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1604839208',
    review_datetime_utc: '11/08/2020 12:40:08',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/113867683662824337324?hl=en-US',
    autor_name: 'Nithya Priya',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GiGz9xNHrpVSdC6hdQWjUPA598M7KCv5ai9KmSLUQ=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Delicious food, excellent service from Raju bhai(new restaurant) ..must try',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GiGz9xNHrpVSdC6hdQWjUPA598M7KCv5ai9KmSLUQ',
    owner_answer: 'Thank you very much 🙏🙏 please do come again.',
    owner_answer_timestamp: '1603618159',
    owner_answer_timestamp_datetime_utc: '10/25/2020 09:29:19',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s113867683662824337324!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1603288224',
    review_datetime_utc: '10/21/2020 13:50:24',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/116034469937074578333?hl=en-US',
    autor_name: 'Anil Sabu',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GjlYQIJ_ReLE0-_7yqfL32gX-BmTKg_sRDApPa0XmE=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text: 'Food is just average considering the high price.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GjlYQIJ_ReLE0-_7yqfL32gX-BmTKg_sRDApPa0XmE',
    owner_answer:
      'Hello Anil, We are sorry to learn that your overall experience 🙏. We take our customer feedback seriously and would like to hear from you how we can make it better. Kindly visit us again and we will ensure great food & service.\r\n\r\nRegards,\r\n\r\nRaju (Manager)',
    owner_answer_timestamp: '1618976260',
    owner_answer_timestamp_datetime_utc: '04/21/2021 03:37:40',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s116034469937074578333!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '1',
    review_timestamp: '1618760359',
    review_datetime_utc: '04/18/2021 15:39:19',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/103303482456749378985?hl=en-US',
    autor_name: 'Dr. Siddharth Abimanyu',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GhHP6epEdfRC7VBXK_kfoja-uV0yuHfm8mtPsjLIA=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text: 'The food is great try the fish tikka and chicken tikka its one of the best in Singapore!',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GhHP6epEdfRC7VBXK_kfoja-uV0yuHfm8mtPsjLIA',
    owner_answer:
      "Thank\r\nyou Siddharth ji... Your kind words is what keeps us going in this time..  Hope to see you again.. and don't forget to tag your friends and family..  FYG your name will entitle them for surprise discount on the food...  :)",
    owner_answer_timestamp: '1604319582',
    owner_answer_timestamp_datetime_utc: '11/02/2020 12:19:42',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s103303482456749378985!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1604306816',
    review_datetime_utc: '11/02/2020 08:46:56',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/114918105222609686982?hl=en-US',
    autor_name: 'Ranu Bhandari',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJyVp-NRwEnUQz-b-pKQ5i2ac04m1KGvXwUTqcui=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text: "Food wasn't exceptional, but, wasn't bad either. Service was exceptional. Staff was very courteous..",
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJyVp-NRwEnUQz-b-pKQ5i2ac04m1KGvXwUTqcui',
    owner_answer: 'Thank you very much your visiting our restaurant please give me a chance to serve again 🙏🙏',
    owner_answer_timestamp: '1602962886',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:28:06',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s114918105222609686982!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1599217469',
    review_datetime_utc: '09/04/2020 11:04:29',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/102643095927800461980?hl=en-US',
    autor_name: 'Rajagopalan Thyagarajan',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJyF7u5Xquakbeppjn2SD3-opprLR71RpfN8ho0b=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'I would rate this place as the best in customer service even before I mention about the food. We were served by a young girl and two middle aged men and all of them were outstanding. They did not have any qualms about who should attend to our table as all 3 of them took turns to ensure that we were well served. Kudos to the team. Food took a while to arrive but no complaints about waiting as the taste was authentic, fresh and delicious. Little disappointed with Briyani as it was cooked like Pulav than dum briyani. Food is expensive but they have very good ongoing promotions if you book through eatigo. I would definitely recommend this place to anyone who wants to eat here.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJyF7u5Xquakbeppjn2SD3-opprLR71RpfN8ho0b',
    owner_answer: 'Thank you very much sir 🙏🙏\r\nPlease do come back.',
    owner_answer_timestamp: '1605447527',
    owner_answer_timestamp_datetime_utc: '11/15/2020 13:38:47',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s102643095927800461980!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1605356031',
    review_datetime_utc: '11/14/2020 12:13:51',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/116376238299452506976?hl=en-US',
    autor_name: 'Jeremiah Jed',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJwUKFPB5i6wf4GLyvHSbXQuGv8Tw4keM4a9GYFV=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Great food and wonderful service. Really enjoyed their Naan and mutton biryani too.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJwUKFPB5i6wf4GLyvHSbXQuGv8Tw4keM4a9GYFV',
    owner_answer: 'Mr Jeremiah jed\r\nThank you very much sir your visiting our restaurant and your kind 🙏🙏',
    owner_answer_timestamp: '1602962593',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:23:13',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s116376238299452506976!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1599400228',
    review_datetime_utc: '09/06/2020 13:50:28',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/115289399337602597946?hl=en-US',
    autor_name: 'rithvika akula',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14Gjc0U79vNd_-kiKQY5SvqmCQH5NCfozDDpr6Ig2qw=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Excellent taste and as well as good service. Love it!!!!!',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14Gjc0U79vNd_-kiKQY5SvqmCQH5NCfozDDpr6Ig2qw',
    owner_answer: 'Thank you very much 🙏🙏\r\nyour visit our restaurant please do come back..',
    owner_answer_timestamp: '1607707159',
    owner_answer_timestamp_datetime_utc: '12/11/2020 17:19:19',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s115289399337602597946!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1607655913',
    review_datetime_utc: '12/11/2020 03:05:13',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/116453498753624224555?hl=en-US',
    autor_name: 'Ravi Kolagani',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GjcN9v9B-WQmsmiezJzCUasuOFjfBGRY0AvhZL3qw=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text:
      "Must try is their Tandoori chicken, its one of the best I would say. Recently I have tried spicy brocoli as a starter it's also superb.",
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GjcN9v9B-WQmsmiezJzCUasuOFjfBGRY0AvhZL3qw',
    owner_answer:
      'RAVI BHAI\r\nthank you very much sir your visiting our restaurant please visit again we are happy to serve you 🙏🙏',
    owner_answer_timestamp: '1602963835',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:43:55',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s116453498753624224555!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1595724968',
    review_datetime_utc: '07/26/2020 00:56:08',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/114409551262337422981?hl=en-US',
    autor_name: 'Saurabh Takkar',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJxQ4sVePutuLw0Fiw4X1w3_r2YmVHZU1yJlQEML=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'The food is quite awesome at this place. We often visit here and order dishes like fish tikka, chilli mushroom, butter chicken, buna ghost, kadai paneer. All the snacks and main course items are wonderful. This is traditional north indian style cooking and I would recommend this place to all Indian food lovers.\r\n\r\nLast but not the least , staff and host of the place is superb. Great hospitality !!',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJxQ4sVePutuLw0Fiw4X1w3_r2YmVHZU1yJlQEML',
    owner_answer: 'Thank you very much SAURABH BHAI please do come back 🙏🙏',
    owner_answer_timestamp: '1604558219',
    owner_answer_timestamp_datetime_utc: '11/05/2020 06:36:59',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s114409551262337422981!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1604550564',
    review_datetime_utc: '11/05/2020 04:29:24',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/102240448321237451862?hl=en-US',
    autor_name: 'Jairaj Amin',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GgwbI3e0StpWdtRzcJjflw0JhNhzE-avc3Jooyn_A=s120-c-c0x00000000-cc-rp-mo-ba2-br100',
    review_text: 'Good & tasty food with Excellent service, my favorite joint for drinks and food in Singapura.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GgwbI3e0StpWdtRzcJjflw0JhNhzE-avc3Jooyn_A',
    owner_answer:
      'Mr Jairaj amin\r\nThank you very much your always supporting our restaurant and your making motivation we are still learning\r\nThank you 🙏🙏',
    owner_answer_timestamp: '1602961816',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:10:16',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s102240448321237451862!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1601437619',
    review_datetime_utc: '09/30/2020 03:46:59',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/103413919811040690247?hl=en-US',
    autor_name: 'Vaughn Friesen',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GgrGVaNqH3bGBRsb02sOSHkeEaLF6KF_tdf5ZYBzA=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Great food and an even better view!',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GgrGVaNqH3bGBRsb02sOSHkeEaLF6KF_tdf5ZYBzA',
    owner_answer: 'Thank you very much sir your visit our restaurant please do come back 🙏🙏',
    owner_answer_timestamp: '1607584953',
    owner_answer_timestamp_datetime_utc: '12/10/2020 07:22:33',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s103413919811040690247!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1607502178',
    review_datetime_utc: '12/09/2020 08:22:58',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/101807362672029711711?hl=en-US',
    autor_name: 'Senthil Nathan',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GhrXt3570MwmWUTl1-Pf2OLMXr2UQCltomSvpe67Q=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text: 'wonderful service, decent food at right price',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GhrXt3570MwmWUTl1-Pf2OLMXr2UQCltomSvpe67Q',
    owner_answer: 'Thank you so much 🙏🙏\r\nMr senthil Nathan\r\nPlease do come again.',
    owner_answer_timestamp: '1603785047',
    owner_answer_timestamp_datetime_utc: '10/27/2020 07:50:47',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s101807362672029711711!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '4',
    review_timestamp: '1603783210',
    review_datetime_utc: '10/27/2020 07:20:10',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/104962010781747298945?hl=en-US',
    autor_name: 'Lalit Naraian',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GgmF4WbIaz5G6l87uwAqBbYeCw2X-TVUOuqmQbyKg=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'One of the best places in singapore for indian food.\r\nService is also good with a beautiful lake view :)',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GgmF4WbIaz5G6l87uwAqBbYeCw2X-TVUOuqmQbyKg',
    owner_answer: 'Mr Laliht Naryan\r\nThank you very much your visiting our restaurant. Very kind 🙏🙏',
    owner_answer_timestamp: '1602961573',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:06:13',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s104962010781747298945!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1601732783',
    review_datetime_utc: '10/03/2020 13:46:23',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/106090703396789160947?hl=en-US',
    autor_name: 'Ahmed Muzammil (Mzee)',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GhsfDxB79UgWrdkP9tPV06z7Xl50FppsXhuefeTGKU=s120-c-c0x00000000-cc-rp-mo-ba4-br100',
    review_text: 'Good food... Great service.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GhsfDxB79UgWrdkP9tPV06z7Xl50FppsXhuefeTGKU',
    owner_answer:
      'Thank you very much sir 🙏🙏\r\nFor your visit to our restaurant. Hope to see you back here soon....:)',
    owner_answer_timestamp: '1604303144',
    owner_answer_timestamp_datetime_utc: '11/02/2020 07:45:44',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s106090703396789160947!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1604298333',
    review_datetime_utc: '11/02/2020 06:25:33',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/109195329704464419701?hl=en-US',
    autor_name: 'Subra',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GjyEULgUa2ZZjF_DqN4-c2XxMerLPcNbYZXrFSz=s120-c-c0x00000000-cc-rp-mo-ba2-br100',
    review_text: 'Food was delicious , ambiance and service were outstanding , spl thanks to Raju’s hospitality',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GjyEULgUa2ZZjF_DqN4-c2XxMerLPcNbYZXrFSz',
    owner_answer:
      'Thank you very much sir your visiting our restaurant and your saport thank you very much your motivation we are serving much better 🙏🙏',
    owner_answer_timestamp: '1602961292',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:01:32',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s109195329704464419701!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1602205254',
    review_datetime_utc: '10/09/2020 01:00:54',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/109750742055872631690?hl=en-US',
    autor_name: 'lalit kunwar singh',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GhmzogX_rjQSXGCpLgtITRZe-BwOu2U5vJYceHSu-M=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Very nice location. Raju bhai rocks.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GhmzogX_rjQSXGCpLgtITRZe-BwOu2U5vJYceHSu-M',
    owner_answer: 'Thank you very much  lalit bhai\r\nPlease do back again 🙏🙏',
    owner_answer_timestamp: '1604406378',
    owner_answer_timestamp_datetime_utc: '11/03/2020 12:26:18',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s109750742055872631690!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1604403421',
    review_datetime_utc: '11/03/2020 11:37:01',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/100934212028171751592?hl=en-US',
    autor_name: 'Jen Lee',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJy2Rd2o-R06oxo_fRgrALzYPYdZdwc82Jlnw40m=s120-c-c0x00000000-cc-rp-mo-ba2-br100',
    review_text:
      'We were not sure about the place cause it was quite empty. But we are so glad we sat down! Food is amazing nice scenery and service is excellent!\r\n\r\nWe had Samosa chaat, Chicken Tikka Masala, Palak Paneer and Garlic Naan.\r\n\r\nWe will be back!',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJy2Rd2o-R06oxo_fRgrALzYPYdZdwc82Jlnw40m',
    owner_answer:
      'thank you Jen Lee for your kind gesture and appreciation on the service and food. Please do come back.',
    owner_answer_timestamp: '1602596964',
    owner_answer_timestamp_datetime_utc: '10/13/2020 13:49:24',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s100934212028171751592!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1602591900',
    review_datetime_utc: '10/13/2020 12:25:00',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/107092703276724183759?hl=en-US',
    autor_name: 'Patibandla A',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJz6p_SeH8nPWQ7_sxPY0ttobLxLcQrs1DxqH4If=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Tawa fish fry is very delicious...',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJz6p_SeH8nPWQ7_sxPY0ttobLxLcQrs1DxqH4If',
    owner_answer: 'Thank you very much sir 🙏\r\nPlease do come back..',
    owner_answer_timestamp: '1604736024',
    owner_answer_timestamp_datetime_utc: '11/07/2020 08:00:24',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s107092703276724183759!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1604731858',
    review_datetime_utc: '11/07/2020 06:50:58',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/102274406378032343175?hl=en-US',
    autor_name: 'Prashant Kulkarni',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJxgHKVIcXfeSsfZwRjH9LtRwoOPhIqczWaSi-uz=s120-c-c0x00000000-cc-rp-mo-ba2-br100',
    review_text: 'Good food and good host and service',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJxgHKVIcXfeSsfZwRjH9LtRwoOPhIqczWaSi-uz',
    owner_answer: 'Thank you very much sir 🙏🙏\r\nPlease do come back..',
    owner_answer_timestamp: '1606051286',
    owner_answer_timestamp_datetime_utc: '11/22/2020 13:21:26',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s102274406378032343175!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '4',
    review_timestamp: '1605935974',
    review_datetime_utc: '11/21/2020 05:19:34',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/113863603074864373515?hl=en-US',
    autor_name: 'Priya Ganesan',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJzg8gQc-nBc128BnNfW-xYq-LnLngt5w-lD0aCu=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Right off the bat, the service was great. Waiter Ashok and the 2 waitresses were super on the ball, replenishing water, papadum and mint sauce at our table when it was going to finish. The wait for the food wasn’t too long either; just perfect to soak in the great ambience. Food was delicious! Especially the fish tikka and muttton shish kebab. We were even given free desserts! Although slightly pricey, it’s a reasonable price for the high quality food and service.  My family and I loved our experience there, and would love to go back again some time soon.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJzg8gQc-nBc128BnNfW-xYq-LnLngt5w-lD0aCu',
    owner_answer: 'Thank you very much your visiting our restaurant very kind 🙏🙏',
    owner_answer_timestamp: '1602961505',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:05:05',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s113863603074864373515!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1601831728',
    review_datetime_utc: '10/04/2020 17:15:28',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/118301236305341245458?hl=en-US',
    autor_name: 'Nabin Thapa',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJyx9-jZ3l_R5_Bnsx6UMLfZdkd9Tjqkba_GUJK_=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Good food & service',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJyx9-jZ3l_R5_Bnsx6UMLfZdkd9Tjqkba_GUJK_',
    owner_answer: 'Thanksfor visiting 🙏🙏\r\nOur resturent plees do come back.',
    owner_answer_timestamp: '1613602923',
    owner_answer_timestamp_datetime_utc: '02/17/2021 23:02:03',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s118301236305341245458!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1613568890',
    review_datetime_utc: '02/17/2021 13:34:50',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/113789752198194327151?hl=en-US',
    autor_name: 'Craig Richardson',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GgqSl_tpIqUBaJdy5KjXRh9z-ChUEN6RiyZy2Oj=s120-c-c0x00000000-cc-rp-mo-ba4-br100',
    review_text: 'Great good, great service and top menu that meets expectations.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GgqSl_tpIqUBaJdy5KjXRh9z-ChUEN6RiyZy2Oj',
    owner_answer:
      'Thank you very much sir your visiting our restaurant please visit again we are happy to serve you 🙏🙏',
    owner_answer_timestamp: '1602963756',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:42:36',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s113789752198194327151!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1595935531',
    review_datetime_utc: '07/28/2020 11:25:31',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/114919287072905123661?hl=en-US',
    autor_name: 'rahul ranjan',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GjwZM-wdoAl6DoHhFrX__qlgU7dC054YCqplx-ulg=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'service was not up to the mark,i reserved a seat but they allocated to someone else',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GjwZM-wdoAl6DoHhFrX__qlgU7dC054YCqplx-ulg',
    owner_answer:
      'Thank you very much sir 🙏\r\nYour visit our restaurant sorry about that we have your feedback and doit better\r\nPlease come again..',
    owner_answer_timestamp: '1608625510',
    owner_answer_timestamp_datetime_utc: '12/22/2020 08:25:10',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s114919287072905123661!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '1',
    review_timestamp: '1608599084',
    review_datetime_utc: '12/22/2020 01:04:44',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/109608796332043279064?hl=en-US',
    autor_name: 'Prabhjot singh',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJyi068gHBuLG5q0iIm6ItynHVfFwyyAgr387yPf=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Awesome food and friendly staff. Recommend everyone to must visit .',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJyi068gHBuLG5q0iIm6ItynHVfFwyyAgr387yPf',
    owner_answer: 'Thank you very much bhai your visiting our restaurant please visit again 🙏🙏',
    owner_answer_timestamp: '1602964138',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:48:58',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s109608796332043279064!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1592576928',
    review_datetime_utc: '06/19/2020 14:28:48',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/104710209546662215649?hl=en-US',
    autor_name: 'Vikash Rai',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14Gh1hFW6mdrOl-wvZqgWsN9zFZmORf77OvL4knJuDw=s120-c-c0x00000000-cc-rp-mo-ba2-br100',
    review_text: 'Highly recommended, good food and friendly service',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14Gh1hFW6mdrOl-wvZqgWsN9zFZmORf77OvL4knJuDw',
    owner_answer:
      'Mr VIKASH rai\r\nThank you very much sir your visiting our restaurant please give me a chance to serve again 🙏🙏',
    owner_answer_timestamp: '1602962946',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:29:06',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s104710209546662215649!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '4',
    review_timestamp: '1599182117',
    review_datetime_utc: '09/04/2020 01:15:17',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/111853675710955221355?hl=en-US',
    autor_name: 'natbusa',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GgrqNrNeUtV1jIVY4Pn1NNHgEQPLoPcJBuOezCOq3U=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text: 'Waited for 90 minutes for food, then left.',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GgrqNrNeUtV1jIVY4Pn1NNHgEQPLoPcJBuOezCOq3U',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s111853675710955221355!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '1',
    review_timestamp: '1611987317',
    review_datetime_utc: '01/30/2021 06:15:17',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/111051002930001979265?hl=en-US',
    autor_name: 'Amit Joshi',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GifQ81lYetJ5GzvDrEoCPrZ9fdzODP1BUb-UASd7A=s120-c-c0x00000000-cc-rp-mo-ba4-br100',
    review_text: 'Superb place with tasty food and great service !!',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GifQ81lYetJ5GzvDrEoCPrZ9fdzODP1BUb-UASd7A',
    owner_answer: 'Amit Bhai\r\nthank you very much your are saporing allwes 🙏🙏',
    owner_answer_timestamp: '1602963926',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:45:26',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s111051002930001979265!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1595298596',
    review_datetime_utc: '07/21/2020 02:29:56',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/107037171242506090688?hl=en-US',
    autor_name: 'Mehar Dadhich',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GjMkBN2Vy8cGI14cmZwjOFJwJUBihQIXG87JSQUj30=s120-c-c0x00000000-cc-rp-mo-ba4-br100',
    review_text: 'Great food... good service...!!!',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GjMkBN2Vy8cGI14cmZwjOFJwJUBihQIXG87JSQUj30',
    owner_answer: 'Thank you very much sir your visiting our restaurant and alwesh saporting 🙏🙏',
    owner_answer_timestamp: '1602961882',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:11:22',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s107037171242506090688!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '4',
    review_timestamp: '1600583441',
    review_datetime_utc: '09/20/2020 06:30:41',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/109912580166640980057?hl=en-US',
    autor_name: 'sandeep suwalka',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJzKPFHEuKy-0C43-_N-pspBGtcj1kE79sf7IHtj=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Nice place for food and drink!',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJzKPFHEuKy-0C43-_N-pspBGtcj1kE79sf7IHtj',
    owner_answer: 'Thank you very much sir your visiting our restaurant please visit again 🙏🙏',
    owner_answer_timestamp: '1602963711',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:41:51',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s109912580166640980057!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1596092074',
    review_datetime_utc: '07/30/2020 06:54:34',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/115513425180572185891?hl=en-US',
    autor_name: 'मनोज पारीक',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GgZAiZ5HvQmc9JrkMy1joI_EzkLnvi04z_HJn52Hw=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Great food',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GgZAiZ5HvQmc9JrkMy1joI_EzkLnvi04z_HJn52Hw',
    owner_answer: 'MONOJ BHAI\r\nThank you very much 🙏🙏\r\nYour visit our restaurant please do come back..',
    owner_answer_timestamp: '1608569387',
    owner_answer_timestamp_datetime_utc: '12/21/2020 16:49:47',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s115513425180572185891!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1608565469',
    review_datetime_utc: '12/21/2020 15:44:29',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/105008718047228410468?hl=en-US',
    autor_name: 'vicnaysen vilsamy',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GiP_LMRCL_EgzomM8IcEg_HQEcHlBB1e14tcIkyXA=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Ok',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GiP_LMRCL_EgzomM8IcEg_HQEcHlBB1e14tcIkyXA',
    owner_answer: 'Thank you very much sir your visiting our restaurant we are teck your feedback please do come back',
    owner_answer_timestamp: '1615728321',
    owner_answer_timestamp_datetime_utc: '03/14/2021 13:25:21',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s105008718047228410468!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '3',
    review_timestamp: '1614830126',
    review_datetime_utc: '03/04/2021 03:55:26',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/111926742987298504660?hl=en-US',
    autor_name: 'Desmond Soh',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14Gii1F8PPqw5P0gFkua42myV2wFWPAL6DIGIlmOYAQ=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Nice service and food',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14Gii1F8PPqw5P0gFkua42myV2wFWPAL6DIGIlmOYAQ',
    owner_answer:
      'Mr Desmond soh\r\nThank you very much sir your visiting our restaurant\r\nWe are keep trying to do our best 🙏🙏',
    owner_answer_timestamp: '1602962499',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:21:39',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s111926742987298504660!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1599626866',
    review_datetime_utc: '09/09/2020 04:47:46',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/113924655821691288139?hl=en-US',
    autor_name: 'Subhasis Pradhan',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJz3S3AQNjScq0tQfiWrQvPLCh-UbqG6yhmsywmX=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Must try.',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJz3S3AQNjScq0tQfiWrQvPLCh-UbqG6yhmsywmX',
    owner_answer:
      'Thank you very much sir 🙏\r\nYour visit our restaurant we are plesar to serving you please do come again..',
    owner_answer_timestamp: '1609723380',
    owner_answer_timestamp_datetime_utc: '01/04/2021 01:23:00',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s113924655821691288139!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1609690492',
    review_datetime_utc: '01/03/2021 16:14:52',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/102052981727910861950?hl=en-US',
    autor_name: 'Yan Harun',
    autor_image:
      'https://lh3.googleusercontent.com/a-/AOh14GiHv-wqJuNsu6noEUJ6I29V2sgyrFVJ2BoePgjuh9w=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Love it!',
    review_img_url: 'https://lh3.googleusercontent.com/a-/AOh14GiHv-wqJuNsu6noEUJ6I29V2sgyrFVJ2BoePgjuh9w',
    owner_answer: 'Thank you very much 🙏🙏\r\nPlease do come back..',
    owner_answer_timestamp: '1605753004',
    owner_answer_timestamp_datetime_utc: '11/19/2020 02:30:04',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s102052981727910861950!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '4',
    review_timestamp: '1605725765',
    review_datetime_utc: '11/18/2020 18:56:05',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/109020093472008852099?hl=en-US',
    autor_name: 'Lakshmanan arunachalam',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJxLCcLmQf370JhY52enIacmtuPJec0O-bP-7OA4_pI=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Bit expensive',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJxLCcLmQf370JhY52enIacmtuPJec0O-bP-7OA4_pI',
    owner_answer: 'Thank you very much sir 🙏🙏\r\nPlease do come back.',
    owner_answer_timestamp: '1606051339',
    owner_answer_timestamp_datetime_utc: '11/22/2020 13:22:19',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s109020093472008852099!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '4',
    review_timestamp: '1606308629',
    review_datetime_utc: '11/25/2020 12:50:29',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/108105294940775752922?hl=en-US',
    autor_name: 'NAVEEN KUMAR',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJyZ82LXg-wibP9WveLo9SWV2LEoc2AWh1lUW07p=s120-c-c0x00000000-cc-rp-mo-ba3-br100',
    review_text: 'Worst',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJyZ82LXg-wibP9WveLo9SWV2LEoc2AWh1lUW07p',
    owner_answer:
      'Mr Naveen Kumar\r\nThank you very much sir your visiting our restaurant soory about that please visit again we are doing our best please give me one more chance 🙏🙏',
    owner_answer_timestamp: '1602964093',
    owner_answer_timestamp_datetime_utc: '10/17/2020 19:48:13',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s108105294940775752922!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '1',
    review_timestamp: '1606842518',
    review_datetime_utc: '12/01/2020 17:08:38',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/110372682895481141765?hl=en-US',
    autor_name: 'NAVEEN CHAUDHARY',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJxNzcB70ZwJ9LZLriimNOIEzLUnBkNbznD6hM2z=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text: 'Worst',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJxNzcB70ZwJ9LZLriimNOIEzLUnBkNbznD6hM2z',
    owner_answer: 'Thank you very much sir 🙏🙏',
    owner_answer_timestamp: '1605797113',
    owner_answer_timestamp_datetime_utc: '11/19/2020 14:45:13',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s110372682895481141765!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '1',
    review_timestamp: '1605794399',
    review_datetime_utc: '11/19/2020 13:59:59',
  },
  {
    autor_link: 'https://www.google.com/maps/contrib/113865292107518988858?hl=en-US',
    autor_name: 'Namit Sehgal',
    autor_image:
      'https://lh3.googleusercontent.com/a/AATXAJy6XrxS7DTJ8T2SApTwazlWADosJlVfaZ_FD-0k=s120-c-c0x00000000-cc-rp-mo-br100',
    review_text:
      'Staying in Singapore for 17 years and have tested almost all Indian  restruant food in  Singapore.\r\nTandoori Zaika has the most Authentic Indian food I ever had here in Singapore.\r\nMost important with increase of customer base, quality of food is still up to standards they started first with.\r\nCustomer servuce is excellent and they entertain each and every customer in call and delivers food in time as well.\r\n\r\nI have tried\r\nDal Makhni\r\nButter chicken\r\nGulab Jamun\r\nKadai Paneer\r\nHara Bhara Kabab\r\nManchorian\r\n\r\nThanks',
    review_img_url: 'https://lh3.googleusercontent.com/a/AATXAJy6XrxS7DTJ8T2SApTwazlWADosJlVfaZ_FD-0k',
    review_link:
      'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s113865292107518988858!2s0x0:0x8b2885839ef752a7?hl=en-US',
    review_rating: '5',
    review_timestamp: '1623552362',
    review_datetime_utc: '06/13/2021 02:46:02',
  },
];
