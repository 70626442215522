/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Star } from './Star';

const Root = styled.div`
  svg {
    .border {
      fill: ${({ theme }) => theme.primaryColor};
    }
  }
`;

const StarWithProp = styled(Star)`
  .checked {
    fill: ${(props) => (props.checked ? props.theme.primaryColor : 'none')};
  }
`;

export const StarRating = ({ rating }) => {
  //
  return (
    <Root rating={rating}>
      <StarWithProp checked={rating >= 1} />
      <StarWithProp checked={rating >= 2} />
      <StarWithProp checked={rating >= 3} />
      <StarWithProp checked={rating >= 4} />
      <StarWithProp checked={rating === 5} />
    </Root>
  );
};

StarRating.propTypes = {
  rating: PropTypes.number.isRequired,
};
